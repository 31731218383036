import React from "react";
import { Modal, Box, Fade, Backdrop } from "@mui/material";
import useTitanApp from "../../hooks/useTitanApp";
import CustomButton from "../controls/CustomButton";
import { setIsCCOpenModal } from "../../dispatchers/clinicalChecksDispatchers";
import CustomLoader from "../controls/CustomLoader";
import { PiPillFill } from "react-icons/pi";

const ClinicalChecksModal = () => {
  const { clincicalChecksState } = useTitanApp();
  const { isCCModalOpen, CCModalLoader } = clincicalChecksState || {};

  const handleClose = () => {
    setIsCCOpenModal(false); // Close the modal
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    boxShadow: 24,
    p: 2,
    borderRadius: 2,
    textAlign: "center",
  };

  return (
    <Modal
      aria-labelledby="spring-modal-title"
      aria-describedby="spring-modal-description"
      open={isCCModalOpen} // Controlled by Redux
      onClose={(_, reason) => {
        // Prevent closing the modal when clicking outside or pressing ESC
        if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
          handleClose();
        }
      }}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          TransitionComponent: Fade,
        },
      }}
    >
      <Fade in={isCCModalOpen}>
        <Box sx={style} className="template-confirmation-box">
          {/* {CCModalLoader ? (
            <CustomLoader />
          ) : ( */}
          <>
            <div
              id="delete-modal-title"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                gap: "5px",
              }}
            >
              <div>
                <PiPillFill className="icons-color" size={24} />
              </div>
              <p style={{ margin: 0, fontSize: "14px" }}>
                {" "}
                There are no clinical checks to process
              </p>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "20px",
              }}
            >
              <CustomButton label={"Close"} action={handleClose} />
            </div>
          </>
          {/* )} */}
        </Box>
      </Fade>
    </Modal>
  );
};

export default ClinicalChecksModal;
