import React from "react";

export default function OrderingTooltipTrayDetail({ data }) {
  const currentOrderTrolleyProfile = data?.orderTrolleyProfiles?.find(
    (profile) =>
      profile.orderTrolleyProfileId ===
      data?.currentOrderPad?.orderTrolleyProfileId
  );

  return (
    <div className="trolley-detail">
      <div className="d-flex">
        <p className="label">Current orders</p>
        <p className="value"></p>
      </div>
      <div className="d-flex">
        <p className="label">{currentOrderTrolleyProfile?.profileName}</p>
        <p className="value">{currentOrderTrolleyProfile?.sumQuantity}</p>
      </div>
    </div>
  );
}
