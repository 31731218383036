import React from "react";
import { TiMinus } from "react-icons/ti";
import { MdQuestionMark } from "react-icons/md";
import { RiShoppingBasketLine } from "react-icons/ri";

export default function OrderingToolTipWithFlavoursHeader({ data,isFirst }) {
  return (
    <>
      <div className="ordering-tooltip-Flavourheader" style={{ marginTop: isFirst ? 0 : '20px' }}>
        <div>
          <h5>{data?.itemName}</h5>
          <p> - Pack: {data?.packSize}</p>
        </div>
        <div>
          <div className="order-basket-icon">
            <div
              className="attr"
              style={{
                backgroundColor: 'red',
                border: `2px solid red`,
              }}
            >
             <TiMinus size={18}/>
            </div>
            <span>
              <RiShoppingBasketLine
                className="ordering-tooltip-icon"
                size={18}
                color={'grey'}
              />
            </span>
          </div>
        </div>
      </div>
    </>
  );
}
