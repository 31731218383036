import { getObject } from "../getObjectValue";

class updateToolTipFlavours {
  constructor(
    data,
    newData,
    recordExists,
    stockItemId,
    isQuantityUpdate,
    isBrandUpdate,
    value,
    matchedPrescriptionItem,
    prescriptions
  ) {
    const numberOfPacks =
      recordExists === true ? newData?.newQuantity : data?.numberOfPacks;
    const newQuantity =
      stockItemId === data?.stockItemId
        ? isQuantityUpdate === true
          ? parseInt(value)
          : numberOfPacks
        : numberOfPacks;
    const originalOrderTrolleyProfileId =
      recordExists === true
        ? newData?.originalOrderTrolleyProfileId
        : data?.currentOrderPad?.orderTrolleyProfileId;
    const orderTrolleyProfileId =
      recordExists === true
        ? newData?.newOrderTrolleyProfileId
        : data?.currentOrderPad?.orderTrolleyProfileId;
    const newOrderTrolleyProfileId =
      stockItemId === data?.stockItemId
        ? isBrandUpdate === true
          ? value
          : orderTrolleyProfileId
        : orderTrolleyProfileId;
    this.stockItemId = data?.stockItemId;
    this.originalQuantity = 0;
    this.newQuantity = newQuantity;
    this.originalOrderTrolleyProfileId = originalOrderTrolleyProfileId;
    this.updateDefaultProfile = false;
    this.newOrderTrolleyProfileId = newOrderTrolleyProfileId;
    this.prescriptionItemId = matchedPrescriptionItem?.prescriptionItemId;
    this.prescriptionIncomingId = prescriptions?.prescriptionIncomingId;
    this.epsLineItem = matchedPrescriptionItem?.epsLineItem;
    this.prescriptionId = prescriptions?.prescriptionId;
    this.patientId = prescriptions?.patientId;
    this.selectedProductId = "00000000-0000-0000-0000-000000000000";
    this.updateDefaultProduct = false;
    this.prescribedItemId = matchedPrescriptionItem?.prescribedItemId;
    this.dosageText = matchedPrescriptionItem?.dosageText;
  }
}

export default updateToolTipFlavours;
