import React, { useState, useEffect } from "react";
import "./../../assets/css/TemplateSection.scss";
import CustomLabel from "../controls/CustomLabel";
import CustomAccordion from "./CustomAccordion";
import { inputTypes } from "../../enum/inputTypes";
import CustomDropdown from "../controls/CustomDropdown";
import Switch from "@mui/material/Switch";
import Radio from "@mui/material/Radio";
import { BsCalendar3 } from "react-icons/bs";
import RadioGroup from "@mui/material/RadioGroup";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FollowUpControl from "../FollowUpControl/FollowUpControl";
import { styled } from "@mui/material/styles";
import useTitanApp from "../../hooks/useTitanApp";
import { setCurrentTimelineElements } from "../../dispatchers/nmsDispatchers";
import NMSNotes from "../NMSNotes/NMSNotes";
import NmsPrescriptionToolTip from "../NmsPrescriptionToolTip/NmsPrescriptionToolTip";
import OrderingTooltip from "../OrderingTooltip/OrderingTooltip";
function TemplateSection({
  SectionTitle,
  templateSection,
  boxStyling,
  groupId,
  number,
  Type,
  UserControls,
  handleChangeModel,
  isCompleted,
  stageStatus,
  pharmacyId,
  ClinicalpatientId,
  clinicalServiceId,
  darkModeChange,
  stageNo,
  handleFollowUpModel,
  order,
  patientName,
  patientNameChange,
  serviceID,
  templateRequired,
}) {
  const { auth, nmsServiceState } = useTitanApp();
  const { user } = auth || {};

  const { timelineElements } = nmsServiceState || {};
  const { name } = user || {};
  const theme = {
    color: `${darkModeChange === false ? "#000" : "#fff"}`,
  };
  useEffect(() => {
    setInputValues(UserControls);
    UserControls?.map((item, index) => {
      if (item.type === 9 && item.value !== "") {
        setAddedTexts((addedTexts) => [...addedTexts, index]);
      }
    });
  }, [UserControls]);
  let rowCount = 0;
  useEffect(() => {
    setIsRequired(isCompleted);
  }, [isCompleted]);
  const [inputValues, setInputValues] = useState(UserControls);
  const [isRequired, setIsRequired] = useState(isCompleted);
  const [switchClass, setSwitchclass] = useState("switch-content-reports");
  const [isExceeded, setIsExceeded] = useState(false);
  function handleChangeColor(isChecked) {
    !isChecked
      ? setSwitchclass("switch-content-reports active-switch")
      : setSwitchclass("switch-content-reports");
  }
  let alreadyControlRendered = [];
  const [addedTexts, setAddedTexts] = useState([]);
  const addText = async (index) => {
    if (addedTexts.includes(index)) {
      const updatedNumbers = addedTexts.filter(
        (addedTexts) => addedTexts !== index
      );
      setAddedTexts(updatedNumbers);
      setInputValues((prevInputValues) => {
        const newInputValues = [...prevInputValues];
        newInputValues[index].value = "";
        return newInputValues;
      });
    } else {
      setAddedTexts((addedTexts) => [...addedTexts, index]);
    }
  };

  const handleFormChange = async (index, event, type, labelName, itemValue) => {
    let value;
    if (type === "Radio") {
      const itemValue = event.target.checked ? "true" : "false";
      value = itemValue;

      handleChangeColor(value);

      // Retrieve the existing element index in the timelineElements array
      const existingElementIndex = timelineElements.findIndex(
        (element) => element.title === SectionTitle
      );

      if (itemValue === "false" && existingElementIndex !== -1) {
        // Remove the item if the switch is false and the element exists
        const updatedListItems = timelineElements[
          existingElementIndex
        ].listItems.filter((item) => item.label !== labelName);

        const updatedTimelineElement = {
          ...timelineElements[existingElementIndex],
          listItems: updatedListItems,
        };

        const updatedTimelineElements = [
          ...timelineElements.slice(0, existingElementIndex),
          updatedTimelineElement,
          ...timelineElements.slice(existingElementIndex + 1),
        ];

        setCurrentTimelineElements(updatedTimelineElements);
      } else if (itemValue === "true") {
        // Add the item if the switch is true
        const timelineListItemObj = {
          label: labelName,
          value: itemValue,
          type: type,
        };

        if (existingElementIndex !== -1) {
          // If the title exists, merge the list items
          const updatedListItems = [
            ...timelineElements[existingElementIndex].listItems,
            timelineListItemObj,
          ];

          const updatedTimelineElement = {
            ...timelineElements[existingElementIndex],
            listItems: updatedListItems,
          };

          const updatedTimelineElements = [
            ...timelineElements.slice(0, existingElementIndex),
            updatedTimelineElement,
            ...timelineElements.slice(existingElementIndex + 1),
          ];

          setCurrentTimelineElements(updatedTimelineElements);
        } else {
          // If the title doesn't exist, create a new object
          const timelineElementsObj = {
            name: SectionTitle,
            title: SectionTitle,
            value: SectionTitle,
            listItems: [timelineListItemObj],
            elementType: 8,
          };

          const updatedTimelineElements = [
            ...timelineElements,
            timelineElementsObj,
          ];
          setCurrentTimelineElements(updatedTimelineElements);
        }
      }
    } else if (type === "date") {
      value = event.toISOString();

      const timelineElementsObj = {
        name: SectionTitle,
        title: value,
        value: value,
        listItems: [],
        elementType: 8,
      };

      const updatedTimelineElements = [
        ...timelineElements,
        timelineElementsObj,
      ];
      setCurrentTimelineElements(updatedTimelineElements);
    } else if (type === "textarea") {
      setIsExceeded(false);
      value = event?.target?.value;
      if (value.length > 4999) {
        setIsExceeded(true);
      }
      const existingElementIndex = timelineElements.findIndex(
        (element) => element.title === SectionTitle
      );
      const timelineListItemObj = {
        label: labelName,
        value: value,
        type: type,
      };

      let updatedListItems;

      if (existingElementIndex !== -1) {
        // If the title exists, merge the list items
        const listitems = timelineElements[existingElementIndex].listItems;
        let otherIndex = listitems.findIndex(
          (item) => item.label === labelName
        );
        if (otherIndex !== -1) {
          updatedListItems = listitems?.map((item, index) => {
            if (index === otherIndex) {
              // Modify the specific element at otherIndex
              return {
                ...item,
                value: value,
              };
            }
            return item;
          });
        } else {
          updatedListItems = [
            ...timelineElements[existingElementIndex].listItems,
            timelineListItemObj,
          ];
        }

        const updatedTimelineElement = {
          ...timelineElements[existingElementIndex],
          listItems: updatedListItems,
        };

        const updatedTimelineElements = [
          ...timelineElements.slice(0, existingElementIndex),
          updatedTimelineElement,
          ...timelineElements.slice(existingElementIndex + 1),
        ];

        setCurrentTimelineElements(updatedTimelineElements);
      } else {
        // If the title doesn't exist, create a new object
        const timelineElementsObj = {
          name: SectionTitle,
          title: SectionTitle,
          value: SectionTitle,
          listItems: [timelineListItemObj],
          elementType: 7,
        };

        const updatedTimelineElements = [
          ...timelineElements,
          timelineElementsObj,
        ];
        setCurrentTimelineElements(updatedTimelineElements);
      }
    } else {
      value = event?.target?.value;

      const existingElementIndex = timelineElements.findIndex(
        (element) => element.title === SectionTitle
      );

      const timelineListItemObj = {
        label: labelName,
        value: value,
        type: type,
      };

      let updatedListItems;

      if (existingElementIndex !== -1) {
        // If the title exists, merge the list items
        const listitems = timelineElements[existingElementIndex].listItems;
        let otherIndex = listitems.findIndex(
          (item) => item.label === labelName
        );
        if (otherIndex !== -1) {
          updatedListItems = listitems?.map((item, index) => {
            if (index === otherIndex) {
              // Modify the specific element at otherIndex
              return {
                ...item,
                value: value,
              };
            }
            return item;
          });
        } else {
          updatedListItems = [
            ...timelineElements[existingElementIndex].listItems,
            timelineListItemObj,
          ];
        }

        const updatedTimelineElement = {
          ...timelineElements[existingElementIndex],
          listItems: updatedListItems,
        };

        const updatedTimelineElements = [
          ...timelineElements.slice(0, existingElementIndex),
          updatedTimelineElement,
          ...timelineElements.slice(existingElementIndex + 1),
        ];

        setCurrentTimelineElements(updatedTimelineElements);
      } else {
        // If the title doesn't exist, create a new object
        const timelineElementsObj = {
          name: SectionTitle,
          title: SectionTitle,
          value: SectionTitle,
          listItems: [timelineListItemObj],
          elementType: 7,
        };

        const updatedTimelineElements = [
          ...timelineElements,
          timelineElementsObj,
        ];
        setCurrentTimelineElements(updatedTimelineElements);
      }
    }
    const UserControlObj = await setUserControl(index, value);
    handleChangeModel(SectionTitle, UserControlObj, groupId);
  };
  const setUserControl = async (index, value) => {
    let updatedValues;

    await setInputValues((prevInputValues) => {
      const newInputValues = [...prevInputValues];

      if (newInputValues?.length > 0) {
        newInputValues[index] = { ...newInputValues[index], value };
        updatedValues = [...newInputValues];
      }
      return newInputValues;
    });

    return updatedValues;
  };
  const [inputclass, setInputClass] = useState("disable");
  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",
    "&:active": {
      "& .MuiSwitch-thumb": {
        width: 15,
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(9px)",
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: 2,
      "&.Mui-checked": {
        transform: "translateX(12px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor:
            theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: 12,
      height: 12,
      borderRadius: 6,
      transition: theme.transitions.create(["width"], {
        duration: 200,
      }),
    },
    "& .MuiSwitch-track": {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor:
        theme.palette.mode === "dark"
          ? "rgba(255,255,255,.35)"
          : "rgba(0,0,0,.25)",
      boxSizing: "border-box",
    },
  }));

  function UpdateNmsModel(title, userControls, updatedgroupId) {
    handleChangeModel(title, userControls, updatedgroupId);
  }
  return (
    <div
      className="parent_template"
      style={{
        borderLeft: templateRequired
          ? "2px solid #E22424"
          : "2px solid #00ca69",
      }}
    >
      <div className="medicine-card customcard">
        <div
          className="card-step-number"
          style={{
            background: templateRequired ? "#E22424" : "#00ca69",
          }}
        >
          <h2>{number}</h2>
        </div>
        <div className="card-content">
          <div className="card-actions nms-title">
            {order === 99 ? (
              <BsCalendar3 className="icons-color" />
            ) : Type === 3 ? (
              <svg
                width="20"
                height="20"
                viewBox="0 0 12 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M10.2497 15.97H1.52274C1.03261 15.97 0.631728 15.6187 0.571922 15.1669H2.52215V7.16827H0.579398C0.621449 6.80097 0.745266 6.44606 0.953184 6.13003L1.65684 5.05821C1.85635 4.75457 1.96147 4.40539 1.96147 4.04738V3.43125H9.81146V4.04738C9.81146 4.40539 9.91612 4.75457 10.1156 5.05821L10.8198 6.13003C11.0272 6.44606 11.1515 6.80097 11.1935 7.16827H6.72772V15.1669H11.2005C11.1407 15.6187 10.7399 15.97 10.2497 15.97ZM0.562547 14.6359H1.96471V7.69844H0.562547V14.6359ZM1.6813 2.90052H2.24198V1.03047H1.6813V2.90052ZM2.80317 2.90052H3.36432V1.03047H2.80317V2.90052ZM3.92505 2.90052H4.48572V1.03047H3.92505V2.90052ZM5.0438 2.90052H5.60495V1.03047H5.0438V2.90052ZM6.1688 2.90052H6.72994V1.03047H6.1688V2.90052ZM7.28755 2.90052H7.84823V1.03047H7.28755V2.90052ZM8.40942 2.90052H8.97057V1.03047H8.40942V2.90052ZM9.5313 2.90052H10.092V1.03047H9.5313V2.90052ZM7.28755 14.6359V7.69844H11.2128V8.76805H8.40937V9.29844H11.2128V9.83501H8.40937V10.3654H11.2128V10.902H8.40937V11.4324H11.2128V11.9685H8.40937V12.4989H11.2128V13.0355H8.40937V13.5663H11.2128V14.6359H7.28755ZM11.2963 5.84983V5.84939L10.5922 4.77757C10.4483 4.55878 10.3721 4.30597 10.3721 4.04696V3.43083C10.5268 3.43083 10.652 3.31238 10.652 3.16564V0.765193C10.652 0.618895 10.5268 0.5 10.3721 0.5H1.4017C1.24658 0.5 1.12136 0.618895 1.12136 0.765193V3.16564C1.12136 3.31238 1.24658 3.43083 1.4017 3.43083V4.04696C1.4017 4.30597 1.32507 4.55878 1.18117 4.77757L0.477045 5.84983C0.164466 6.32541 0 6.87304 0 7.43348V7.51083V14.8235V15.0516C0 15.8503 0.683095 16.5 1.52318 16.5H10.2502C11.0903 16.5 11.7738 15.8503 11.7738 15.0516V14.8235V7.51083V7.43348C11.7738 6.87304 11.6089 6.32541 11.2963 5.84983Z"
                  className="icons-color"
                />
              </svg>
            ) : (
              <svg
                width="16"
                height="14"
                viewBox="0 0 16 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.49652 13.8028L14.7408 7.49941C16.4252 5.78856 16.4199 3.02549 14.7239 1.31249C13.0611 -0.367079 10.3961 -0.410504 8.69784 1.19086C8.66122 1.22539 8.62506 1.26068 8.58936 1.29673L7.99299 1.90026L7.38843 1.28963C7.35529 1.25616 7.32175 1.22333 7.28783 1.19116C5.58595 -0.422744 2.91654 -0.398067 1.26122 1.2739C-0.427101 2.9792 -0.418861 5.75004 1.27679 7.46275L7.55368 13.8028C7.81404 14.0657 8.23617 14.0657 8.49652 13.8028ZM9.3 2.00029C10.5964 0.691352 12.7025 0.692036 14.0133 2.01604C15.3253 3.34123 15.3272 5.47734 14.0292 6.79681L14.0282 6.79783L8.02517 12.8577L1.98743 6.75919C0.674081 5.43263 0.672858 3.28953 1.97185 1.97746C3.26525 0.671064 5.36984 0.672083 6.6778 1.99319L7.63801 2.96306C7.8338 3.16082 8.15338 3.16067 8.34898 2.96272L9.3 2.00029Z"
                  className="icons-color"
                />
              </svg>
            )}

            <h2 className="medicine-card-heading">{SectionTitle}</h2>
          </div>
          {
            <div className="accordion-boxes">
              {inputValues?.map((item, index) => {
                if (item.inline === true) {
                  rowCount > 2 ? (rowCount = 0) : rowCount++;
                }

                if (item.type === 7) {
                  return (
                    <React.Fragment key={index}>
                      <div
                        className={
                          item.inline === true ? "inline-not" : "inline"
                        }
                      >
                        <div className="outer-item">
                          <CustomDropdown
                            label={item.label}
                            items={item.listItems}
                            selected={item.value}
                            isEdit={stageStatus}
                          />
                        </div>
                      </div>
                      {rowCount > 2 ? <br /> : <></>}
                    </React.Fragment>
                  );
                }
                if (item.type === 6) {
                  return (
                    <React.Fragment key={index}>
                      <div
                        className={
                          item.inline === true ? "inline-not" : "inline"
                        }
                      >
                        <div className="switch-content-reports">
                          <CustomLabel name={item.label} />
                          <div>
                            <AntSwitch
                              disabled={stageStatus}
                              inputProps={{ "aria-label": "ant design" }}
                              onChange={(event) =>
                                handleFormChange(
                                  index,
                                  event,
                                  inputTypes[item.type],
                                  item.label,
                                  item.value
                                )
                              }
                              defaultChecked={item.value}
                            />
                          </div>
                        </div>
                      </div>
                      {rowCount > 2 ? <br /> : <></>}
                    </React.Fragment>
                  );
                }
                if (item.type === 5) {
                  return (
                    <React.Fragment key={index}>
                      <div
                        className={
                          item.inline === true ? "inline-not" : "inline"
                        }
                      >
                        <div
                          style={{
                            border: templateRequired
                              ? "1px solid #E22424"
                              : "#00ca69",
                          }}
                          className="switch-content-reports-outer toggle-only"
                          onClick={(event) =>
                            handleFormChange(
                              index,
                              { target: { checked: !JSON.parse(item?.value) } },
                              inputTypes[item?.type],
                              item?.label,
                              item?.value
                            )
                          }
                        >
                          <CustomLabel name={item.label} />

                          <div>
                            <AntSwitch
                              disabled={stageStatus}
                              inputProps={{ "aria-label": "ant design" }}
                              onChange={(event) =>
                                handleFormChange(
                                  index,
                                  event,
                                  inputTypes[item.type],
                                  item.label,
                                  item.value
                                )
                              }
                              checked={
                                item.value === ""
                                  ? "false"
                                  : JSON.parse(item.value)
                              }
                            />
                          </div>
                        </div>
                      </div>
                      {rowCount > 2 ? <br /> : <></>}
                    </React.Fragment>
                  );
                }

                if (item.type === 8) {
                  return (
                    <FollowUpControl
                      key={index}
                      Title={SectionTitle}
                      pharmacyId={pharmacyId}
                      ClinicalpatientId={ClinicalpatientId}
                      clinicalServiceId={clinicalServiceId}
                      darkModeChange={darkModeChange}
                      stageStatus={stageStatus}
                      stageNo={stageNo}
                      handleFollowUpModel={handleFollowUpModel}
                      patientName={patientName}
                      serviceID={serviceID}
                      SectionTitle={SectionTitle}
                    />
                  );
                }
                if (item.type === 0) {
                  return (
                    <React.Fragment key={index}>
                      <div
                        className={
                          item.inline === true ? "inline-not" : "inline"
                        }
                      >
                        <div className="template_control">
                          <TextField
                            className="template_input text_input_field"
                            error={
                              item.required
                                ? isCompleted === true
                                  ? item.value === ""
                                    ? true
                                    : false
                                  : false
                                : false
                            }
                            required
                            id="outlined-required"
                            disabled={stageStatus}
                            value={
                              item.label === "Patient Name"
                                ? patientNameChange === null
                                  ? item.value
                                  : patientNameChange
                                : item.value
                            }
                            label="Comments"
                            onChange={(event) =>
                              handleFormChange(
                                index,
                                event,
                                inputTypes[item.type],
                                item.label,
                                item.value
                              )
                            }
                            inputProps={{ maxLength: 5000 }}
                          />
                        </div>
                        {isExceeded && (
                          <p className="max-word-limit">
                            *Your character limit has been reached
                          </p>
                        )}
                      </div>

                      {rowCount > 2 ? <br /> : <></>}
                    </React.Fragment>
                  );
                }
                if (item.type === 3) {
                  return (
                    <React.Fragment key={index}>
                      <div
                        className={
                          item.inline === true ? "inline-not" : "inline"
                        }
                      >
                        <div className="template_control">
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              className="template_input text_input_field"
                              label={item.label}
                              defaultValue={dayjs(
                                item.value === null ? "2022-04-17" : item.value
                              )}
                              disabled={stageStatus}
                              onChange={(newValue) =>
                                handleFormChange(
                                  index,
                                  newValue,
                                  inputTypes[item.type],
                                  item.label,
                                  item.value
                                )
                              }
                            />
                          </LocalizationProvider>
                        </div>
                      </div>

                      {rowCount > 2 ? <br /> : <></>}
                    </React.Fragment>
                  );
                }
                if (item.type === 1) {
                  return (
                    <React.Fragment key={index}>
                      <div
                        className={
                          item.inline === true
                            ? "inline-not two-line"
                            : "inline"
                        }
                      >
                        <div className="template_control_label">
                          {SectionTitle === "Pharmacist" ? (
                            item.label === "Name" ? (
                              <span>
                                <CustomLabel name={item.label} />
                                <p className="card-inner-value">{name}</p>
                              </span>
                            ) : // <TextField
                            //   className="template_input text_input_field"
                            //   id="outlined-required"
                            //   disabled={true}
                            //   value={name}
                            //   label={item.label}
                            // />
                            item.label === "Member ID" ? (
                              <span>
                                <CustomLabel name={item.label} />
                                <p className="card-inner-value">{"1234"}</p>
                              </span>
                            ) : (
                              // <TextField
                              //   className="template_input text_input_field"
                              //   id="outlined-required"
                              //   disabled={true}
                              //   value="1234"
                              //   label={item.label}
                              // />
                              <TextField
                                className="template_input text_input_field"
                                id="outlined-required"
                                disabled={true}
                                value={item.value}
                                label="Comments"
                              />
                            )
                          ) : (
                            <span>
                              <CustomLabel name={item.label} />
                              <p className="card-inner-value">{item.value}</p>
                            </span>
                            // <TextField
                            //   className="template_input text_input_field"
                            //   id="outlined-required"
                            //   disabled={true}
                            //   value={item.value}
                            //   label={item.label}
                            // />
                          )}
                        </div>
                      </div>
                      {rowCount > 2 ? <br /> : <></>}
                    </React.Fragment>
                  );
                }
                if (item.type === 9) {
                  return (
                    <React.Fragment key={index}>
                      <div
                        className={
                          item.inline === true ? "inline-not" : "inline"
                        }
                      >
                        <div className="radio-text-box-outer">
                          <div className="switch-content-reports-outer">
                            <CustomLabel name={item.label} />
                            <div>
                              <AntSwitch
                                disabled={stageStatus}
                                inputProps={{ "aria-label": "ant design" }}
                                onChange={(event) => addText(index)}
                                checked={addedTexts.includes(index)}
                              />
                            </div>
                          </div>
                          {addedTexts.includes(index) ? (
                            <div key={index} className="template_control">
                              <TextField
                                className="template_input text_input_field"
                                error={
                                  item.required
                                    ? isCompleted === true
                                      ? item.value === ""
                                        ? true
                                        : false
                                      : false
                                    : false
                                }
                                required
                                id="outlined-required"
                                disabled={stageStatus}
                                label="Required"
                                defaultValue={item.value}
                                onChange={(event) =>
                                  handleFormChange(
                                    index,
                                    event,
                                    inputTypes[item.type],
                                    item.label,
                                    item.value
                                  )
                                }
                              />
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                      {rowCount > 2 ? <br /> : <></>}
                    </React.Fragment>
                  );
                }
                if (item.type === 10) {
                  return (
                    <React.Fragment key={index}>
                      <div
                        className={
                          item.inline === true ? "inline-not" : "inline"
                        }
                      >
                        <div className="template_control">
                          {/* <CustomLabel name={item.label} />

                        <textarea
                          key={index}
                          rows="4"
                          onInput={(event) =>
                            handleFormChange(
                              index,
                              event,
                              inputTypes[item.type]
                            )
                          }
                          disabled={stageStatus}
                          value={item.value}
                        ></textarea> */}

                          <TextField
                            className="template_input textfield"
                            error={
                              item.required
                                ? isCompleted === true
                                  ? item.value === ""
                                    ? true
                                    : false
                                  : false
                                : false
                            }
                            id="outlined-multiline-static"
                            label="Comments"
                            multiline
                            rows={4}
                            disabled={stageStatus}
                            defaultValue={item.value}
                            onChange={(event) =>
                              handleFormChange(
                                index,
                                event,
                                inputTypes[item.type],
                                item.label,
                                item.value
                              )
                            }
                            inputProps={{ maxLength: 5000 }}
                          />
                        </div>
                        {isExceeded && (
                          <p className="max-word-limit">
                            *Your character limit has been reached
                          </p>
                        )}
                      </div>

                      {rowCount > 2 ? <br /> : <></>}
                    </React.Fragment>
                  );
                }
                if (item.type === 11) {
                  return (
                    <React.Fragment key={index}>
                      <div
                        className={
                          item.inline === true ? "inline-not" : "inline"
                        }
                      >
                        <div className="multi_select_outer">
                          <CustomLabel name={item.label} />
                          <div className="multiSelect_div">
                            {item?.listItems?.map((listItems, idx) => {
                              return (
                                <FormControlLabel
                                  key={idx}
                                  className="multiselect_label"
                                  control={
                                    <Checkbox
                                      value={listItems.value}
                                      onChange={(event) =>
                                        handleFormChange(
                                          index,
                                          event,
                                          inputTypes[item.type],
                                          item.label,
                                          item.value
                                        )
                                      }
                                      disabled={stageStatus}
                                    />
                                  }
                                  label={listItems.label}
                                />
                              );
                            })}
                          </div>
                        </div>
                      </div>
                      {rowCount > 2 ? <br /> : <></>}
                    </React.Fragment>
                  );
                }
                if (item?.type === 12) {
                  return (
                    <React.Fragment key={index}>
                      <div
                        className={
                          item?.inline === true ? "inline-not" : "inline"
                        }
                      >
                        <div className="template_control">
                          <TextField
                            error={
                              item?.required
                                ? isCompleted === true
                                  ? item?.value === ""
                                    ? true
                                    : false
                                  : false
                                : false
                            }
                            multiline
                            rows={4}
                            required
                            id="outlined-required"
                            disabled={stageStatus}
                            label="Comments"
                            defaultValue={item?.value}
                            onChange={(event) =>
                              handleFormChange(
                                index,
                                event,
                                inputTypes[item?.type],
                                item.label,
                                item.value
                              )
                            }
                            inputProps={{ maxLength: 5000 }}
                          />
                        </div>
                        {isExceeded && (
                          <p className="max-word-limit">
                            *Your character limit has been reached
                          </p>
                        )}
                      </div>

                      {rowCount > 2 ? <br /> : <></>}
                    </React.Fragment>
                  );
                } else {
                  return (
                    <React.Fragment key={index}>
                      <div
                        className={
                          item.inline === true ? "inline-not" : "inline"
                        }
                      >
                        <div
                          // style={boxStyling(UserControls.length, index)}
                          className="outer-item"
                        >
                          <CustomLabel key={index} name={item.label} />
                          <input
                            key={index}
                            className={inputclass + " card-inner-value"}
                            type={inputTypes[item.type]}
                            disabled={stageStatus}
                            value={item.value}
                            onChange={(event) =>
                              handleFormChange(
                                index,
                                event,
                                inputTypes[item.type],
                                item.label,
                                item.value
                              )
                            }
                          />
                        </div>{" "}
                      </div>
                      {rowCount > 2 ? <br /> : <></>}
                    </React.Fragment>
                  );
                }
              })}
            </div>
          }
          {templateSection?.map((item, index) => {
            if (
              item.groupId === groupId &&
              item.isParent === false &&
              item.groupId !== "00000000-0000-0000-0000-000000000000"
            ) {
              return (
                <CustomAccordion
                  key={index}
                  SectionTitle={item.sectionTitle}
                  UserControls={item.userControls}
                  boxStyling={boxStyling}
                  handleChangeModel={UpdateNmsModel}
                  isCompleted={isCompleted}
                  stageStatus={stageStatus}
                  darkModeChange={darkModeChange}
                  mainSectionTitle={SectionTitle}
                  groupId={item?.groupId}
                />
              );
            }
          })}
        </div>
      </div>
    </div>
  );
}

export default TemplateSection;
