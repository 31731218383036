import React, { useState } from "react";
import { Dropdown } from "primereact/dropdown";
import { Checkbox } from "primereact/checkbox";
import { InputText } from "primereact/inputtext";

export default function OrderingTooltipBrandDetails({
  data,
  padData,
  handleBrandCheckboxChange,
  brandChecked,
  handleLastOrderedValue,
  lastOrderedValue,
  brand,
  setBrand,
  orderPad,
  setOrderPad,
}) {
  let brands = data?.availableProducts.map((product) => {
    return {
      label: `${product.companyName}`,
      value: product?.productId,
    };
  });
  let orderPads = padData?.map((data) => {
    return {
      label: `${data.profileName}`,
      value: data?.orderTrolleyProfileId,
    };
  });

  const handleChange = (e) => {
    setBrand(e.value?.productId);
  };

  const handleChangeOrderPad = (e) => {
    setOrderPad(e.value?.orderTrolleyProfileId);
  };

  return (
    <div className="ordering-tooltip-brand-details">
      <div>
        <p className="label">Brand</p>
        <Dropdown
          className="dropdown"
          value={brand}
          onChange={(e) => handleChange(e)}
          options={brands}
          placeholder="Select a Brand"
          scrollHeight="200px"
        />
        <div className="checkbox">
          <Checkbox
            inputId="defaultBrand"
            onChange={handleBrandCheckboxChange}
            checked={brandChecked}
          />
          <label htmlFor="defaultBrand">Set as default brand</label>
        </div>
      </div>
      <div className="d-flex">
        <div>
          <p className="label">Quantity (packs)</p>
          <InputText
            className="ot-input"
            type="number"
            value={lastOrderedValue}
            onChange={handleLastOrderedValue}
          />
        </div>
        <div>
          <p className="label">Order pad</p>
          <Dropdown
            className="dropdown"
            value={orderPad}
            onChange={(e) => handleChangeOrderPad(e)}
            options={orderPads}
            placeholder="Select option"
          />
        </div>
      </div>
    </div>
  );
}
