import React, { useState, useEffect } from "react";
import "../assets/clinicalwarning.css";
import PropTypes from "prop-types";
import { ClinicalCheckActionTypes } from "../Model/ClinicalActions/ClinicalCheckActionTypes";
import {
  ClinicalCheckIdColor,
  ClinicalCheckTitle,
} from "../Model/ClinicalActions/ClinicalCheckIdColor";
import setCheckNow from "../Model/CheckNow/setCheckNow";
import {
  DefaultNotDispensePatientDeceasedCode,
  DefaultNotDispenseOutOfScopeCode,
  DefaultNotDispenseExpiredCode,
  DefaultNotDispenseCancelledCode,
  DefaultClinicallyNotSuitableCode,
} from "../data/constantStrings";
import useTitanApp from "../hooks/useTitanApp";
import setAddMedication from "../Model/AddMedication/setAddMedication";
import {
  setCheckNowModel,
  setSeverityCount,
  setItemReturnDose,
  setEditable,
  setButtonclass,
  setMedicationTime,
  setDispenseArray,
  getPostMedication,
  setMedicineWarning,
  setTheraphyModalContent,
  addEmergencySupplies,
} from "../dispatchers/clinicalChecksDispatchers";
import NmsPrescriptionToolTip from "./NmsPrescriptionToolTip/NmsPrescriptionToolTip";
import EmergencySupplyModal from "./EmergencySupplyModal/EmergencySupplyModal";

let ErdType = 0;
let itemCrossedOut = false,
  qualifiedService = 0,
  uomValue = false,
  notDispensedReasonCode = "";

function MedicineClinicalWarning(props) {
  const [checked, setChecked] = useState("");
  const [isdisabled, setisdisabled] = useState(false);
  const [isEmergency, setIsEmergency] = useState(false);
  const [activeButton, setActiveButton] = useState("");
  const [currentCheckOptionId, setCurrentCheckOptionId] = useState();
  const [currentCheckActionType, setCurrentCheckActionType] = useState();

  let checkDispenseArray = [6, 12, 14, 16, 20];
  const { clincicalChecksState } = useTitanApp();
  const {
    clinicalChecksPharmacy,
    isSkip,
    patientId,
    checkNowModel,
    severityCount,
    itemReturnDose,
    returnQuantity,
    returnDose,
    dispenseArray,
    returnTherapy,
    returnStartDate,
    returnStopDate,
    returnArchive,
    returnPreparation,
    returnMarking,
    returnColor,
    returnReport,
    medicationTime,
    medicineWarning,
    selectedBucketType,
    therapyModelContents,
  } = clincicalChecksState || {};
  const { pharmacyID } = clinicalChecksPharmacy || {
    pharmacyID: "00000000-0000-0000-0000-000000000000",
  };

  const {
    items,
    PrescriptionItemId,
    itemInfo,
    PrescribedItemId,
    medicineValues,
    autoCheckInfo,
    patientMedicationTherapy,
    onOpen,
    prescribedItem,
    setUomDisplay,
    setIsItemExpired,
  } = props || {};
  const {
    title,
    description,
    checkSeverity,
    clinicalCheckId,
    clinicalCheckOptions,
    botDetails,
  } = items || {};
  useEffect(() => {
    if (
      medicineWarning.length > 0 &&
      !medicineWarning?.includes(Number(clinicalCheckId))
    ) {
      setChecked("");
    }
  }, [clinicalCheckId]);

  async function addTherapy(isInterim) {
    if (
      patientMedicationTherapy?.quantity !== "" &&
      patientMedicationTherapy?.dose !== ""
    ) {
      const prescriptionCheckBody = new setAddMedication(
        itemInfo,
        patientMedicationTherapy,
        patientMedicationTherapy?.patientMedicationTime,
        isInterim
      ).obj;
      await getPostMedication({ prescriptionCheckBody, pharmacyID });
    }
  }
  function updatePrescriptionItemEmergency(
    prescription,
    prescribedItemId,
    newEmergencyData
  ) {
    const updatedPrescription = { ...prescription };
    updatedPrescription.prescriptionItems =
      updatedPrescription.prescriptionItems.map((item) => {
        if (item.prescribedItemId === prescribedItemId) {
          const updatedEmergencyData = {
            ...newEmergencyData,
            status: newEmergencyData?.remainingQuantity > item.quantity ? 4 : 2,
          };
          return {
            ...item,
            prescriptionItemEmergency: updatedEmergencyData,
          };
        }
        return item;
      });
    return updatedPrescription;
  }
  function handleAction(clinicalCheckOptionId, clinicalCheckActionType) {
    setCheckAction(clinicalCheckId, clinicalCheckActionType);
    const PrescriptionItemCheckActions = {
      clinicalCheckId: clinicalCheckId,
      clinicalCheckOptionId: clinicalCheckOptionId,
      prescriptionItemId: PrescriptionItemId,
      fullCheckText: description,
      patientMedicationCheckLearningId: "",
      botDetails: botDetails === null ? "" : botDetails,
      IsClinicalBotDecision: false,
    };

    if (uomValue === true) {
      setUomDisplay(uomValue, PrescriptionItemCheckActions);
      uomValue = false;
    } else {
      const newCheckNowModel = new setCheckNow(
        checkNowModel,
        PrescriptionItemCheckActions,
        PrescriptionItemId,
        "",
        0,
        "",
        false,
        "",
        true,
        ErdType,
        itemCrossedOut,
        notDispensedReasonCode,
        false,
        "",
        false,
        "",
        "",
        false,
        selectedBucketType,
        qualifiedService,
        null,
        false,
        null
      );
      setCheckNowModel(newCheckNowModel);
      if (clinicalCheckActionType === 18) {
        addEmergencySupplies({
          pharmacyID,
          patientID: patientId,
          prescribedItemID: PrescribedItemId,
        }).then((data) => {
          const updatedPrescription = updatePrescriptionItemEmergency(
            newCheckNowModel,
            PrescribedItemId,
            data[0]
          );
          setCheckNowModel(updatedPrescription);
        });
        setIsEmergency(false);
      }
    }

    setCSSValues(clinicalCheckOptionId);

    checkDispenseArray?.includes(clinicalCheckActionType)
      ? setDispenseArray([...dispenseArray, PrescriptionItemId])
      : setDispenseArray([...dispenseArray, 0]);
    ErdType = 0;
    itemCrossedOut = false;
    notDispensedReasonCode = "";
    if (checkSeverity == 2) {
      // Check If the checks are mandatory
      setSeverityCount(severityCount - 1);
    }
  }
  function handleEmergencyModal(
    clinicalCheckOptionId,
    clinicalCheckActionType
  ) {
    setIsEmergency(true);
    setCurrentCheckOptionId(clinicalCheckOptionId);
    setCurrentCheckActionType(clinicalCheckActionType);
  }
  function setCSSValues(clinicalCheckOptionId) {
    setActiveButton(clinicalCheckOptionId);
    setChecked("-checked");
    setisdisabled(true);
    setMedicineWarning(clinicalCheckId);
  }

  const setActionDisplay = (clinicalCheckOptionId, clinicalCheckActionType) => {
    setCSSValues(clinicalCheckOptionId);

    checkDispenseArray?.includes(clinicalCheckActionType)
      ? setDispenseArray([...dispenseArray, PrescriptionItemId])
      : setDispenseArray([...dispenseArray, 0]);
    ErdType = 0;
    itemCrossedOut = false;
    notDispensedReasonCode = "";
    if (checkSeverity == 2) {
      // Check If the checks are mandatory
      setSeverityCount(severityCount - 1);
    }
  };
  function setCheckAction(CheckId, ActionType) {
    switch (ActionType) {
      case 29: // Add Erd
        ErdType = 1;
        break;
      case 30: // Add MdsErd
        ErdType = 2;
        break;
      case 5: // Accept
      case 28: // Ignore
        switch (CheckId) {
          case 2: //PatientPartialMatch
            // nothing
            // code block
            break;
          case 44: // RepeatTheraphyExist
            //nothing
            // code block
            break;
          case 54: // EpsErd
            ErdType = 3;
            break;
          default:
            break;
        }
        break;
      case 11: // SearchDrug
        // nothing
        // code block
        break;
      case 6: // NotDispenseOutOfScope
      case 12: //NotDispenseExpired
      case 14: //ClinicallyUnsuitable
      case 20: //NotDispenseDrugNotFound
      case 16: //NotDispenseDentist
        switch (CheckId) {
          case 26: //PatientDeceased
            itemCrossedOut = true;
            notDispensedReasonCode = DefaultNotDispensePatientDeceasedCode;
            break;
          default:
            switch (ActionType) {
              case 20: // NotDispenseDrugNotFound
                itemCrossedOut = true;
                notDispensedReasonCode = DefaultNotDispenseCancelledCode;
                break;
              case 6: //NotDispenseOutOfScope
                itemCrossedOut = true;
                notDispensedReasonCode = DefaultNotDispenseOutOfScopeCode;
                break;

              case 12: //NotDispenseExpired
                setIsItemExpired(true);
                itemCrossedOut = true;
                notDispensedReasonCode = DefaultNotDispenseExpiredCode;
                break;

              case 14: //ClinicallyUnsuitable
              case 16: //NotDispenseDentist
                itemCrossedOut = true;
                notDispensedReasonCode = DefaultClinicallyNotSuitableCode;
                break;
            }
            break;
        }
        break;
      case 7: //AddPatient
        // nothing
        break;
      case 8: //EditPatient
        // nothing
        break;
      case 9: //SearchPatient
        // nothing
        break;
      case 4: //Remove
        // nothing
        break;
      case 13: //PrescriptionItemValidationCheck
        uomValue = true;
        break;
      case 15: //View
        //nothing
        switch (CheckId) {
          case 45:
            //
            break;
          case 44:
            //
            break;
          default:
        }
        break;
      case 17: // AddtoDosage
        setItemReturnDose(
          `${itemReturnDose}. Additonal Info: ${medicineValues?.additionalDosage}`
        );
        // code block
        break;
      case 3: //ChangeQuantity
        setButtonclass(true);
        setEditable(true);
        break;
      case 18: //ReconcileEmergencySupply
        switch (CheckId) {
          case 30: //EmergencySupply
            //
            break;
          case 31: //EmergencySupplyMoreThanRx
            //
            break;
        }
        break;
      case 26: //TreatAsNewPrescription
        // code block
        break;
      case 1000: //ReturnToSpine
        // code block
        break;
      case 21: //UpdateRepeatTherapy
      case 25: //UpdateRepeatQuantity
      case 23: //Restart
        // code block
        // stop date empty in therapy checks
        break;
      case 22: //UseExistingDose
        setItemReturnDose(returnDose);
        break;
      case 24: //AddAsRegular
        // code block
        break;
      case 27: //CreateNMSAlert
        qualifiedService = 1;
        break;
      case 19: //AddAsInterim
        switch (CheckId) {
          case 46:
            //
            break;
          default:
        }
        break;
      default:
      // code block
    }
  }

  return (
    <>
      {description ? (
        <div
          className={`p-message-warn-${ClinicalCheckIdColor[clinicalCheckId]}${checked}`}
        >
          <EmergencySupplyModal
            isEmergency={isEmergency}
            currentCheckActionType={currentCheckActionType}
            currentCheckOptionId={currentCheckOptionId}
            handleSubmit={handleAction}
            setIsEmergency={setIsEmergency}
          />
          <div className="p-message-inner">
            <div className="message-inner-items">
              <button
                className={`warn-button-${ClinicalCheckIdColor[clinicalCheckId]}${checked}`}
              >
                {ClinicalCheckTitle[clinicalCheckId]}
              </button>
              {ClinicalCheckTitle[clinicalCheckId] === "Interaction" && (
                <>
                  <div
                    className={`item-title-${ClinicalCheckIdColor[clinicalCheckId]}`}
                  >
                    {title}
                  </div>
                  <div
                    className={`item-count-${ClinicalCheckIdColor[clinicalCheckId]}`}
                  >
                    1
                  </div>
                </>
              )}
            </div>

            {isSkip ? (
              <></>
            ) : (
              <span>
                {autoCheckInfo !== null && autoCheckInfo?.details !== null ? (
                  <NmsPrescriptionToolTip autoCheckInfo={autoCheckInfo} />
                ) : (
                  <></>
                )}
                {clinicalCheckOptions?.map((key) => {
                  const isButtonActive =
                    key.clinicalCheckOptionId === activeButton;
                  const actionTypeLabel =
                    ClinicalCheckActionTypes[key?.clinicalCheckActionType];

                  return (
                    <button
                      key={key?.clinicalCheckOptionId} // Add a unique key for mapping
                      disabled={isdisabled} // Disable the button if needed
                      className={`issue-button${
                        isButtonActive ? "-active" : ""
                      }`} // Apply active styling
                      onClick={() => {
                        if (
                          key?.clinicalCheckActionType === 15 ||
                          key?.clinicalCheckActionType === 21 ||
                          key?.clinicalCheckActionType === 19 ||
                          key?.clinicalCheckActionType === 24
                        ) {
                          handleAction(
                            key.clinicalCheckOptionId,
                            key.clinicalCheckActionType
                          );
                          onOpen({
                            medicineValues: prescribedItem,
                            prescribedItemId: PrescribedItemId,
                            itemInfo: itemInfo,
                            clinicalCheckId: clinicalCheckId,
                            PrescriptionItemId: PrescriptionItemId,
                            isInterim:
                              key?.clinicalCheckActionType === 19
                                ? true
                                : key?.clinicalCheckActionType === 24
                                ? false
                                : "",
                          });
                        } else if (key.clinicalCheckActionType === 18) {
                          handleEmergencyModal(
                            key.clinicalCheckOptionId,
                            key.clinicalCheckActionType
                          );
                        } else {
                          handleAction(
                            key.clinicalCheckOptionId,
                            key.clinicalCheckActionType
                          ); // Handle other actions
                        }
                      }}
                    >
                      {actionTypeLabel}{" "}
                      {/* Render the label for the action type */}
                    </button>
                  );
                })}
              </span>
            )}
          </div>
          <p>{description}</p>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}
MedicineClinicalWarning.propTypes = {
  items: PropTypes.any.isRequired,
};
export default MedicineClinicalWarning;
