import { getObject } from "../getObjectValue";

class exemption {
  constructor(response) {
    this.obj = {
      prescriptionExemptionId: response.prescriptionExemptionId
        ? getObject(response, "prescriptionExemptionId")
        : "",
      prescriptionId: response.prescriptionId
        ? getObject(response, "prescriptionId")
        : "",
      exemptionStatusType: response.exemptionStatusType
        ? getObject(response, "exemptionStatusType")
        : 0,
      expiryDate: response.expiryDate
        ? getObject(response, "expiryDate")
        : null,
      certificateNumber: response.certificateNumber
        ? getObject(response, "certificateNumber")
        : null,
      name: response.name ? getObject(response, "name") : "",
      dateOfBirth: response.dateOfBirth
        ? getObject(response, "dateOfBirth")
        : null,
      evidenceSeen: response.evidenceSeen
        ? getObject(response, "evidenceSeen")
        : null,
      signatureImage: response.signatureImage
        ? getObject(response, "signatureImage")
        : "",
      createdOn: response.createdOn ? getObject(response, "createdOn") : null,
    };
  }
}

export default exemption;
