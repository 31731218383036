import React, { useState } from "react";
import { Dropdown } from "primereact/dropdown";
import { Checkbox } from "primereact/checkbox";
import { InputText } from "primereact/inputtext";

export default function OrderingToolTipWithFlavoursBrand({
  data,
  padData,
  handleFlavoursValue,
}) {
  const [flavoursQuantity, setFlavoursQuantity] = useState(data?.numberOfPacks);
  const [flavoursOrderPad, setOrderPadFlavour] = useState(
    data?.currentOrderPad?.orderTrolleyProfileId
  );

  let orderPads = padData?.map((data) => {
    return {
      label: `${data.profileName}`,
      value: data?.orderTrolleyProfileId,
    };
  });
  const handleChangeOrderPad = (e) => {
    console.log(e);
    setOrderPadFlavour(e?.target?.value);
    handleFlavoursValue(e?.target?.value, data, "Brand");
  };
  const handleQuantity = (e) => {
    setFlavoursQuantity(e?.target?.value);
    handleFlavoursValue(e?.target?.value, data, "Quantity");
  };
  return (
    <div className="ordering-tooltip-brand-details-question">
      <div className="d-flex">
        <div>
          <p className="label">Quantity (packs)</p>
          <InputText
            className="ot-input"
            type="number"
            value={flavoursQuantity}
            onChange={(e) => handleQuantity(e)}
          />
        </div>
        <div>
          <p className="label">Order pad</p>
          <Dropdown
            className="dropdown"
            value={flavoursOrderPad}
            onChange={(e) => handleChangeOrderPad(e)}
            options={orderPads}
            placeholder="Select option"
          />
        </div>
      </div>
    </div>
  );
}
