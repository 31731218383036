import React, { useEffect, useState } from "react";
import "./../../assets/css/PrescribedMedicines.css";
import { RiMedicineBottleLine } from "react-icons/ri";
import editIcon from "./editIcon.svg";
import chatIcon from "./chaticon.svg";
import checkIcon from "./checkIcon.svg";
import starIcon from "./starIcon.svg";
import EndorsementModal from "../EndorsementModal/EndorsementModal";
import CustomSwitch from "../controls/CustomSwitch";
import CustomLabel from "../controls/CustomLabel";
import CustomDropdown from "../controls/CustomDropdown";
import GroupMedicineWarning from "../GroupMedicineWarning";
import MedicineClinicalWarning from "../MedicineClinicalWarning";
import setCheckNow from "../../Model/CheckNow/setCheckNow";
import NotesControl from "../NotesControl/NotesControl";
import { dispenseItemsArray } from "../../enum/dispenseDropdown";
import useTitanApp from "../../hooks/useTitanApp";
import setPrescriptionItemChecks from "../../Model/PrescriptionItemChecks/setPrescriptionItemChecks";
import {
  setCheckNowModel,
  setItemChecks,
  setTheraphy,
  setReturnDispense,
  setDispense,
  getAdditionalEndorsement,
  getDrugUomFixValue,
} from "../../dispatchers/clinicalChecksDispatchers";
import NmsPrescriptionToolTip from "../NmsPrescriptionToolTip/NmsPrescriptionToolTip";
import OrderingTooltip from "../OrderingTooltip/OrderingTooltip";
import { Dropdown } from "primereact/dropdown";
import PrescribedItemEdit from "./PrescribedItemEdit";

function PrescribedMedicines(props) {
  const { appState, clincicalChecksState } = useTitanApp();

  const { isAppLoading } = appState || {};
  const {
    prescriptions,
    clinicalChecksPharmacy,
    prescriptionItemCheck,
    checkNowModel,
    dispenseArray,
    isTherapy,
    therapyComponents,
    defaultDosageTimings,
    postMedicationId,
    prescriptionChecks,
    returnDispense,
    isReturnDispense,
    notesModel,
    medicationId,
    prescriptionCheckGroups,
    patientId,
    selectedBucketType,
    patientMedication,
  } = clincicalChecksState || {};
  const { pharmacyID } = clinicalChecksPharmacy || {
    pharmacyID: "00000000-0000-0000-0000-000000000000",
  };
  const {
    itemInfo,
    PrescribedItemId,
    PrescriptionItemId,
    number,
    medicineValues,
    prescribedIndex,
    onOpenTherapyModal,
    medicationExists,
    therapyMatch,
  } = props || {};
  const matchExists = notesModel.some(
    (note) => note.prescriptionItemId === PrescriptionItemId
  );
  const matchingNotes = notesModel.filter(
    (note) => note.prescriptionItemId === PrescriptionItemId
  );
  const [doseValue, setDoseValue] = useState(medicineValues?.dosageText);
  const [isEditable, setEditable] = useState(false);
  const [buttonClass, setButtonclass] = useState(false);
  const [quantityValue, setQuantityValue] = useState(medicineValues?.quantity);
  const [isNotes, setIsNotes] = useState(false);
  const [inputclass, setInputClass] = useState("disable");
  const [DispenseLabel, setDispenseLabel] = useState("");
  const [isFixUOM, setIsFixUOM] = useState(false);
  const [uomValue, setUomValue] = useState("");
  const [uomOptionsValue, setUomOptionsValue] = useState([]);
  const [uomData, setuomData] = useState([]);
  const [prescriptionItemCheckActions, setPrescriptionItemCheckActions] =
    useState(null);
  const [isItemExpired, setIsItemExpired] = useState(false);
  const [colorClass, setColorClass] = useState(
    medicationExists ? (therapyMatch ? "orange" : "green") : "blue"
  );

  const [heading, setheading] = useState(
    medicationExists
      ? therapyMatch === false
        ? "Matched with therapy"
        : "Therapy Change"
      : "New drug"
  );
  // initialize therapy object
  const therapyObj = {
    quantity: quantityValue,
    dose: doseValue,
    therapyType: itemInfo !== null ? itemInfo?.therapyType : 0,
    startDate: "2024-01-01",
    stopDate: "2024-01-01",
    archive: itemInfo !== null ? itemInfo?.neverAutoArchive : false,
    preparationType: itemInfo !== null ? itemInfo?.preparationType : 0,
    marking: itemInfo !== null ? itemInfo?.marking : "",
    color: itemInfo !== null ? itemInfo?.colour : 0,
    report: itemInfo !== null ? itemInfo?.showOnReport : false,
    prescribedItemId: PrescribedItemId,
    prescriptionMedicationId: medicineValues?.prescriptionMedicationId,
    patientId: patientId,
    patientMedicationTime:
      itemInfo === null
        ? defaultDosageTimings
        : itemInfo?.patientMedicationTime,
  };
  const [patientMedicationTherapy, setPateintMedicationTherapy] =
    useState(therapyObj);
  const therapyChanges = [44, 45, 46, 47, 48];
  useEffect(() => {
    async function fetchData() {
      prescriptionItemCheck?.map((value) => {
        if (medicationId) {
          if (therapyChanges.includes(value.clinicalCheckId)) {
            setTheraphy(true);
          }
        }
      });
    }
    fetchData();
  }, []);

  function changeDispense(value) {
    setReturnDispense(value);
  }
  async function showmodel() {
    const foundItem = dispenseItemsArray.find(
      (item) => item.value === returnDispense
    );

    setDispenseLabel(foundItem.label);
    const newCheckNowModel = new setCheckNow(
      checkNowModel,
      "",
      PrescriptionItemId,
      "",
      quantityValue,
      doseValue,
      true,
      postMedicationId,
      isReturnDispense,
      0,
      isReturnDispense,
      isReturnDispense === true ? returnDispense : "",
      false,
      "",
      false,
      "",
      "",
      false,
      selectedBucketType,
      0,
      null,
      false,
      null
    );
    setCheckNowModel(newCheckNowModel);
    const prescriptionItemChecksBody = new setPrescriptionItemChecks(
      prescriptions,
      quantityValue,
      doseValue,
      PrescriptionItemId,
      true,
      ""
    ).obj;
    setItemChecks(prescriptionItemChecksBody);

    setButtonclass(!buttonClass);
    setEditable(!isEditable);
  }

  function showbuttons() {
    setButtonclass(!buttonClass);
    setEditable(!isEditable);
  }

  async function getEndorsementforItem() {
    await getAdditionalEndorsement({
      pharmacyID,
      PrescribedItemId,
    });
  }

  function changeitemQuantity(e) {
    const value = e.target.value;
    if (value === "" || value === "0" || /^[1-9]\d{0,2}$/.test(value)) {
      setQuantityValue(value);
    }
  }

  function changeitemDose(e) {
    setDoseValue(e?.target?.value);
  }
  function hideButton() {
    setButtonclass(false);
    setEditable(!isEditable);
    setQuantityValue(medicineValues?.quantity);
    setDoseValue(medicineValues?.dosageText);
  }

  function handleChangeDispensed(checked) {
    setDispense(!checked);
  }
  function notes() {
    setIsNotes(!isNotes);
  }
  function checkPrescriptionActions() {
    let allMatch = true;

    const matchingItem = checkNowModel?.prescriptionItems.find(
      (item) => item?.prescribedItemId === PrescribedItemId
    );
    const flattenedCheckGroups = prescriptionCheckGroups.flat();
    const { prescriptionItemCheckActions } = matchingItem;
    if (
      !flattenedCheckGroups.find(
        (item) => item?.prescribedItemId === PrescribedItemId
      )
    ) {
      allMatch = true;
    } else if (
      flattenedCheckGroups.find(
        (item) => item?.prescribedItemId === PrescribedItemId
      ) &&
      prescriptionItemCheckActions?.length === 0
    ) {
      allMatch = false;
    } else {
      allMatch = flattenedCheckGroups
        .filter((group) => group?.prescribedItemId === PrescribedItemId)
        .every((group) =>
          prescriptionItemCheckActions.some(
            (action) =>
              action?.clinicalCheckId === group?.clinicalCheckId &&
              group?.clinicalCheckOptions.some(
                (option) =>
                  option?.clinicalCheckOptionId ===
                  action?.clinicalCheckOptionId
              )
          )
        );
    }
    return allMatch;
  }
  const changeTherapyData = async (therapyData) => {
    setPateintMedicationTherapy(therapyData);
  };

  const handleDropdownChange = (e) => {
    setUomValue(e.value);
    const uomCode = uomData.find((item) => item.description === e.value);
    const newCheckNowModel = new setCheckNow(
      checkNowModel,
      prescriptionItemCheckActions,
      PrescriptionItemId,
      "",
      0,
      "",
      false,
      "",
      true,
      0,
      false,
      "",
      false,
      "",
      false,
      "",
      "",
      false,
      selectedBucketType,
      0,
      uomCode?.code,
      true,
      null
    );
    setCheckNowModel(newCheckNowModel);
  };

  const setUomDisplay = async (
    uomDisplayValue,
    PrescriptionItemCheckActions
  ) => {
    await getUomFixValue();
    showbuttons();
    setIsFixUOM(uomDisplayValue);
    setPrescriptionItemCheckActions(PrescriptionItemCheckActions);
  };

  const getUomFixValue = async () => {
    let uom = [];
    const prescribedItemID = PrescribedItemId,
      prescriptionOriginCountry =
        prescriptions?.walesBarcode === null ? "1" : "2";
    var result = await getDrugUomFixValue({
      prescribedItemID,
      prescriptionOriginCountry,
      pharmacyID,
    });
    if (result !== null) {
      uom = result.uom;
      setuomData(uom);
      const updated = uom.map((items) => {
        return items.description;
      });
      setUomOptionsValue(updated);
    }
  };

  return (
    <>
      {isAppLoading === false && (
        <>
          <EndorsementModal PrescriptionItemId={PrescriptionItemId} />

          <div
            className="parent_template"
            style={{
              borderLeft:
                checkPrescriptionActions() === true
                  ? "2px solid #00ca69"
                  : "2px solid #E22424",
            }}
          >
            <div className="medicine-card customcard">
              <div
                className="card-step-number numberscart"
                style={{
                  background:
                    checkPrescriptionActions() === true ? "#00ca69" : "#E22424",
                }}
              >
                <h2>{number}</h2>
              </div>
              <div className={`card-header ${colorClass}`}>
                <div className="card-header-title">
                  <img src={starIcon} />
                  <p>{heading}</p>
                </div>
                <img src={checkIcon} />
              </div>

              <div className="card-content re">
                <div className="card-actions">
                  <h2
                    className={
                      isReturnDispense
                        ? "medicine-card-heading-dispensed"
                        : dispenseArray.includes(PrescriptionItemId)
                        ? "medicine-card-heading-dispensed"
                        : "medicine-card-heading"
                    }
                  >
                    <RiMedicineBottleLine />
                    {medicineValues.prescribedItem.writtenAs}
                    {medicineValues?.dosageAutoCheckInfo !== null ? (
                      <NmsPrescriptionToolTip
                        autoCheckInfo={medicineValues?.dosageAutoCheckInfo}
                      />
                    ) : (
                      <></>
                    )}
                  </h2>
                  <div className="card-right-actions">
                    {buttonClass ? (
                      <>
                        <CustomSwitch
                          handleCallback={handleChangeDispensed}
                          defaultValue={isReturnDispense}
                        />
                        <p>Not dispensed</p>
                        <p>{matchExists ? matchingNotes?.length : 0}</p>
                        <img src={chatIcon} onClick={notes} />
                      </>
                    ) : (
                      <>
                        <p>{matchExists ? matchingNotes?.length : 0}</p>
                        <img src={chatIcon} onClick={notes} />
                        <img src={editIcon} onClick={showbuttons} />
                      </>
                    )}
                    <OrderingTooltip
                      PrescribedItemId={PrescribedItemId}
                      itemName={medicineValues.prescribedItem.writtenAs}
                    />
                  </div>
                </div>

                {isItemExpired ? (
                  <div className="do-not-dispense-container">
                    <button className="do-not-dispense-tags">Expired</button>
                    <button className="do-not-dispense-tags">
                      Item or prescription expired
                    </button>
                  </div>
                ) : (
                  <></>
                )}

                {!buttonClass ? (
                  isReturnDispense ? (
                    <>
                      <p className="dispense-label">{DispenseLabel}</p>
                    </>
                  ) : (
                    <></>
                  )
                ) : (
                  <></>
                )}
                {buttonClass ? (
                  isReturnDispense ? (
                    <div className="outer-boxes">
                      <div className="outer-item not-disp-reason">
                        <CustomLabel name="Not dispensed reason" />
                        <CustomDropdown
                          handleChange={changeDispense}
                          label=""
                          items={dispenseItemsArray}
                          selected={returnDispense}
                        />
                      </div>
                    </div>
                  ) : (
                    <></>
                  )
                ) : (
                  <></>
                )}
                <PrescribedItemEdit
                  buttonClass={buttonClass}
                  quantityValue={quantityValue}
                  doseValue={doseValue}
                  medicineValues={medicineValues}
                  isFixUOM={isFixUOM}
                  uomOptionsValue={uomOptionsValue}
                  uomValue={uomValue}
                  isTherapy={isTherapy}
                  handleDropdownChange={handleDropdownChange}
                  changeitemQuantity={changeitemQuantity}
                  changeitemDose={changeitemDose}
                />
                {isTherapy === true ? (
                  <>
                    {isNotes ? (
                      <NotesControl PrescriptionItemId={PrescriptionItemId} />
                    ) : (
                      <></>
                    )}

                    <div
                      className={
                        buttonClass ? "buttons-enable" : "buttons-disable"
                      }
                    >
                      <div className="warning-switch">
                        <CustomSwitch />
                        <p>Show warnings</p>
                      </div>
                      <div className="buttons-bar">
                        <button className="form-buttons" onClick={hideButton}>
                          Cancel
                        </button>
                        <button
                          className="form-buttons"
                          onClick={getEndorsementforItem}
                        >
                          Additional Endorsements
                        </button>
                        <button className="form-buttons" onClick={showmodel}>
                          Add item
                        </button>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    {isNotes ? (
                      <NotesControl PrescriptionItemId={PrescriptionItemId} />
                    ) : (
                      <></>
                    )}
                    <div
                      className={`${
                        buttonClass ? "buttons-enable" : "buttons-disable"
                      }-add`}
                    >
                      {buttonClass ? (
                        <div className="buttons-bar">
                          <button className="form-buttons" onClick={hideButton}>
                            Cancel
                          </button>
                          <button className="form-buttons" onClick={showmodel}>
                            Add item
                          </button>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </>
                )}
                {prescriptionCheckGroups?.map((group, index) => {
                  return group?.length > 1 ? (
                    group[0]?.prescribedItemId === PrescribedItemId ? (
                      <div key={index}>
                        <GroupMedicineWarning
                          key={index}
                          group={group}
                          PrescriptionItemId={PrescriptionItemId}
                          PrescribedItemId={PrescribedItemId}
                          itemInfo={itemInfo}
                          medicineValues={medicineValues}
                          autoCheckInfo={group[0]?.autoCheck}
                          patientMedicationTherapy={patientMedicationTherapy}
                        />
                      </div>
                    ) : (
                      <></>
                    )
                  ) : (
                    <div key={index}>
                      {group?.map((items, innerIndex) => {
                        if (items?.prescribedItemId === PrescribedItemId) {
                          return (
                            <MedicineClinicalWarning
                              key={innerIndex}
                              items={items}
                              itemInfo={itemInfo}
                              PrescribedItemId={PrescribedItemId}
                              PrescriptionItemId={PrescriptionItemId}
                              medicineValues={medicineValues}
                              autoCheckInfo={items?.autoCheck}
                              patientMedicationTherapy={
                                patientMedicationTherapy
                              }
                              onOpen={onOpenTherapyModal}
                              prescribedItem={medicineValues}
                              setUomDisplay={setUomDisplay}
                              setIsItemExpired={setIsItemExpired}
                            />
                          );
                        }
                      })}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default PrescribedMedicines;
