import ClickAwayListener from "@mui/material/ClickAwayListener";
import React from "react";
import { RiShoppingBasketLine } from "react-icons/ri";
import OrderingTooltipHeader from "./components/OrderingTooltipHeader";
import useTitanApp from "../../hooks/useTitanApp";
import OrderingTooltipOrderDetails from "./components/OrderingTooltipOrderDetails";
import OrderingTooltipBrandDetails from "./components/OrderingTooltipBrandDetails";
import { useState } from "react";
import setCCToolTipData from "../../Model/ClinicalChecksToolTip/setCCToolTipData";
import {
  updateCCOrderItemToolTip,
  updateCCOrderItemToolTipFlavours,
} from "../../dispatchers/clinicalChecksDispatchers";
import CustomLoader from "../controls/CustomLoader";
import OrderingTooltipTrayDetail from "./components/OrderingTooltipTrayDetail";
import { BsDash } from "react-icons/bs";
import { MdQuestionMark } from "react-icons/md";
import { RxDash } from "react-icons/rx";
import { TiMinus } from "react-icons/ti";
import OrderingToolTipWithFlavoursHeader from "./components/OrderingToolTipWithFlavoursHeader";
import OrderingToolTipWithFlavoursBrand from "./components/OrderingToolTipWithFlavoursBrand";
import updateToolTipFlavours from "../../Model/ClinicalChecksToolTip/updateToolTipFlavours";

export default function OrderingTooltip({ PrescribedItemId, itemName }) {
  const { clincicalChecksState } = useTitanApp();
  const {
    prescriptions,
    clinicalChecksPharmacy,
    isCCOrderTooltipLoading,
    prescriptionItemToolTip,
    CCOrderTooltipData,
    updateCCOrderTooltipLoading,
  } = clincicalChecksState || {};
  const { pharmacyID } = clinicalChecksPharmacy || {
    pharmacyID: "00000000-0000-0000-0000-000000000000",
  };
  let toolTipRecord;
  const [showTooltip, setShowTooltip] = useState(false);
  let orderToolTipsFlavours = [];
  const [brand, setBrand] = useState("");
  const [brandChecked, setBrandChecked] = useState(false);
  const [lastOrderedValue, setLastOrderedValue] = useState(0);
  const [orderPad, setOrderPad] = useState(0);
  const [orderStatus, setOrderStatus] = useState(0);

  const handleBrandCheckboxChange = (e) => {
    setBrandChecked(e.checked);
  };

  const handleLastOrderedValue = (e) => {
    setLastOrderedValue(e.target.value);
  };
  const handleFlavoursValue = (value, data, type) => {
    const matchedPrescriptionItem = prescriptions.prescriptionItems.find(
      (item) => item.prescribedItemId === PrescribedItemId
    );
    const recordExists = orderToolTipsFlavours.find(
      (item) => item.stockItemId === data?.stockItemId
    );
    const CCUpdateOrderTooltipBody = new updateToolTipFlavours(
      data,
      recordExists ? recordExists : data,
      recordExists ? true : false,
      recordExists ? recordExists?.stockItemId : data?.stockItemId,
      type === "Quantity" ? true : false,
      type === "Brand" ? true : false,
      value,
      matchedPrescriptionItem,
      prescriptions
    );
    const existingIndex = orderToolTipsFlavours.findIndex(
      (item) => item.stockItemId === CCUpdateOrderTooltipBody?.stockItemId
    );
    if (existingIndex !== -1) {
      orderToolTipsFlavours[existingIndex] = CCUpdateOrderTooltipBody;
    } else {
      orderToolTipsFlavours.push(CCUpdateOrderTooltipBody);
    }
  };
  const handleTooltipClose = () => {
    setShowTooltip(false);
  };
  if (itemName !== null) {
    toolTipRecord = prescriptionItemToolTip.find(
      (item) =>
        item?.stockItemTooltipSummary?.prescribedItemId === PrescribedItemId
    );
    if (!toolTipRecord) {
      toolTipRecord = prescriptionItemToolTip.find((item) => {
        const searchItemName = itemName?.trim().toLowerCase();

        return item?.orderTooltipSummaryItemWithFlavours?.some(
          (flavour) => flavour?.prescribedItemId === PrescribedItemId
        );
      });
    }
  }
  const stockSummary = toolTipRecord?.stockItemTooltipSummary;

  const [toolTipData, setToolTipData] = useState(toolTipRecord);
  const handleTooltipOpen = () => {
    setShowTooltip(true);

    setBrand(toolTipData?.stockItemTooltipSummary?.preferredProductId);
    setOrderPad(
      toolTipData?.stockItemTooltipSummary?.currentOrderPad
        ?.orderTrolleyProfileId
    );
    setLastOrderedValue(toolTipData?.stockItemTooltipSummary?.numberOfPacks);
  };
  const handleUpdateFlavourClick = () => {
    try {
      updateCCOrderItemToolTipFlavours({
        orderToolTipsFlavours,
        pharmacyID,
      }).then(() => {
        orderToolTipsFlavours = [];
        setBrand("");
        setBrandChecked(false);
        setOrderPad(0);
        setLastOrderedValue("");
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdateClick = () => {
    const matchedPrescriptionItem = prescriptions.prescriptionItems.find(
      (item) => item.prescribedItemId === PrescribedItemId
    );
    const CCUpdateOrderTooltipBody = {
      stockItemId: toolTipData?.stockItemTooltipSummary?.stockItemId,
      originalQuantity:
        toolTipData?.stockItemTooltipSummary?.lastOrderedQuantity,
      newQuantity: parseInt(lastOrderedValue),
      originalOrderTrolleyProfileId:
        toolTipData?.stockItemTooltipSummary?.currentOrderPad
          ?.orderTrolleyProfileId,
      updateDefaultProfile: true,
      newOrderTrolleyProfileId: orderPad,
      prescriptionItemId: matchedPrescriptionItem?.prescriptionItemId,
      prescriptionIncomingId: prescriptions?.prescriptionIncomingId,
      epsLineItem: matchedPrescriptionItem?.epsLineItem,
      prescriptionId: prescriptions?.prescriptionId,
      patientId: prescriptions?.patientId,
      selectedProductId: brand,
      updateDefaultProduct: brandChecked,
      prescribedItemId: matchedPrescriptionItem?.prescribedItemId,
      dosageText: matchedPrescriptionItem?.dosageText,
    };

    try {
      updateCCOrderItemToolTip({
        CCUpdateOrderTooltipBody,
        pharmacyID,
      }).then(() => {
        setBrand("");
        setBrandChecked(false);
        setOrderPad(0);
        setLastOrderedValue("");
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getTrolletColor = () => {
    let result = "";

    switch (toolTipData?.stockItemTooltipStatus[0]?.orderStatus) {
      case 1:
      case 2:
        result = "orange";
        break;
      case 3:
      case 5:
        result = "grey";
        break;
      case 4:
        result = "green";
        break;
      default:
        result = "grey";
    }

    return result;
  };

  const getIconColor = () => {
    let result = "";

    switch (toolTipData?.stockItemTooltipStatus[0]?.orderStatus) {
      case 1:
      case 2:
        result = "orange";
        break;
      case 3:
        result = "red";
        break;
      case 5:
        result = "grey";
        break;
      case 4:
        result = "green";
        break;
      default:
        result = "grey";
    }

    return result;
  };

  const getQuestionIconColor = () => {
    let result = "";

    switch (toolTipData?.stockItemTooltipStatus[0]?.orderStatus) {
      case 1:
      case 2:
        result = "orange";
        break;
      case 3:
        result = "white";
        break;
      case 5:
        result = "white";
        break;
      case 4:
        result = "green";
        break;
      default:
        result = "white";
    }

    return result;
  };

  const getToolTipValue = () => {
    let result = "";

    switch (toolTipData?.stockItemTooltipStatus[0]?.orderStatus) {
      case 1:
      case 2:
        result = toolTipData?.stockItemTooltipSummary?.numberOfPacks;
        break;
      case 3:
        result = <TiMinus color={getQuestionIconColor()} size={50} />;
        break;
      case 5:
        result = <MdQuestionMark color={getQuestionIconColor()} size={50} />;
        break;
      case 4:
        result = toolTipData?.stockItemTooltipSummary?.numberOfPacks;
        break;
      default:
        result = <MdQuestionMark color={getQuestionIconColor()} size={50} />;
    }

    return result;
  };

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <div style={{ position: "relative" }}>
        <div className="order-basket-icon">
          <div
            className="attr"
            style={{
              backgroundColor: getIconColor(),
              border: `2px solid ${getIconColor()}`,
            }}
          >
            {getToolTipValue()}
          </div>
          <span onClick={handleTooltipOpen}>
            <RiShoppingBasketLine
              className="ordering-tooltip-icon"
              size={18}
              color={getTrolletColor()}
            />
          </span>
        </div>

        {showTooltip && (
          <div>
            {isCCOrderTooltipLoading || updateCCOrderTooltipLoading ? (
              <div className="ordering-tooltip-loader">
                <CustomLoader />
              </div>
            ) : stockSummary ? (
              <div className="ordering-tooltip-wrapper">
                <OrderingTooltipHeader
                  data={toolTipData?.stockItemTooltipSummary}
                />
                <hr />
                <OrderingTooltipOrderDetails
                  data={toolTipData?.stockItemTooltipSummary}
                />
                <hr />
                <OrderingTooltipTrayDetail
                  data={toolTipData?.stockItemTooltipSummary}
                />
                <hr />
                <OrderingTooltipBrandDetails
                  data={toolTipData?.stockItemTooltipSummary}
                  padData={toolTipData?.orderTrolleyProfileModel}
                  handleBrandCheckboxChange={handleBrandCheckboxChange}
                  brandChecked={brandChecked}
                  handleLastOrderedValue={handleLastOrderedValue}
                  setLastOrderedValue={setLastOrderedValue}
                  lastOrderedValue={lastOrderedValue}
                  brand={brand}
                  setBrand={setBrand}
                  setOrderPad={setOrderPad}
                  orderPad={orderPad}
                />
                <button
                  onClick={handleUpdateClick}
                  className="ordering-tooltip-action-btn"
                >
                  Update
                </button>
              </div>
            ) : (
              <div className="ordering-tooltip-wrapper-question">
                {toolTipRecord?.orderTooltipSummaryItemWithFlavours?.map(
                  (item, index) => {
                    return (
                      <>
                        <OrderingToolTipWithFlavoursHeader
                          data={item}
                          isFirst={index === 0}
                        />
                        <OrderingToolTipWithFlavoursBrand
                          data={item}
                          padData={toolTipData?.orderTrolleyProfileModel}
                          handleFlavoursValue={handleFlavoursValue}
                        />
                      </>
                    );
                  }
                )}
                <button
                  onClick={handleUpdateFlavourClick}
                  className="ordering-tooltip-action-btn"
                >
                  Update
                </button>
              </div>
            )}
          </div>
        )}
      </div>
    </ClickAwayListener>
  );
}
