import { useAuth0 } from "@auth0/auth0-react";
import { useSelector, useStore } from "react-redux";
import { store } from "../store/store";
import { defer, redirect } from "react-router-dom";
import {
  setCurrentPatientName,
  setCurrentIsNmsLoading,
  setCurrentIsNmsPatientLoading,
  setCurrentIsNmsTimelineLoading,
  setCurrentIsNmsPrescriptionLoading,
  setCurrentIsServiceNotesLoading,
  setCurrentStepNoNms,
  setCurrentstageNoNms,
  setCurrentNmsModel,
  setCurrentvalueNms,
  setCurrentArrayImg,
  setCurrentPatientNMSData,
  getClinicalServiceByClinicalServiceId,
  getClinicalServiceFromOrbit,
  getTitanPatient,
  getPharmacyIdForOrbitUser,
  getTimeline,
  getServiceNotes,
  getFollowUpByServiceIdAndServiceType,
  getPatientByTelephoneAndStageIndexResult,
  setCurrentIsPatientNmsTelephoneLoading,
  setCurrentResponseDTOStagesData,
  getTotalSkips,
  getPrescriptionImagesByIncomingIds,
  getTravelInformation,
} from "../dispatchers/nmsDispatchers";
import {
  showInfo,
  showError,
} from "../components/ToastedMessage/ToastedMessage";
import NotFound from "../components/NotFound/NotFound";
import Dashboard from "../pages/Dashboard/Dashboard";
import ClinicalChecks from "../pages/ClinicalChecks/ClinicalChecks";
import PatientForm from "../pages/PatientForm/PatientForm";
import NmsIntervention from "../pages/NmsIntervention/NmsIntervention";
import ClinicalServices from "../pages/ClinicalServices/ClinicalServices";
import PharmacyServices from "../pages/PharmacyServices/PharmacyServices";
import PharmacyOverview from "../pages/PharmacyOverview/PharmacyOverview";
import PharmacyPatient from "../pages/PharmacyPatient/PharmacyPatient";
import Orbit from "../pages/Orbit/Orbit";
import PatientDashboard from "../components/PatientDashboard/PatientDashboard";
import General from "../components/SettingsOptions/General/General";
import Locations from "../components/SettingsOptions/Locations/Locations";
import Billing from "../components/SettingsOptions/Billing/Billing";
import Teams from "../components/SettingsOptions/Teams/Teams";
import Services from "../components/SettingsOptions/Services/Services";
import Templates from "../components/SettingsOptions/Templates/Templates";
import Members from "../components/SettingsOptions/Teams/components/Members/Members";
import Roles from "../components/SettingsOptions/Teams/components/Roles/Roles";
import CustomTemplate from "../components/SettingsOptions/Templates/components/Consultation/CustomTemplate";
import Types from "../components/SettingsOptions/Services/components/Types/Types";
import MembersAddNew from "../components/SettingsOptions/Teams/components/Members/components/MembersAddNew/MembersAddNew";
import MembersUpdate from "../components/SettingsOptions/Teams/components/Members/components/MembersUpdate/MembersUpdate";
import LocationsAddNew from "../components/SettingsOptions/Locations/components/LocationsAddNew";
import Success from "../pages/UserOnboarding/Success";
import Failure from "../pages/UserOnboarding/Failure";
import VerificationEmail from "../pages/UserOnboarding/VerificationEmail";
import { logError } from "../services/logService";
import {
  getPrescriptionIncomingIds,
  getPrescriptionByIncomingId,
  getPatientMedication,
  getPrescriptionItemCheck,
  getPrescriptionChecks,
  getPatientImage,
  setPatientImage,
  setCCOrderItemToolTip,
  setIsPrescriptionItemToolTip,
} from "../dispatchers/clinicalChecksDispatchers";
import UpdateRole from "../components/SettingsOptions/Teams/components/Roles/components/RolesAddNew/UpdateRole";
import {
  setAppLoading,
  setTabLoading,
  setCurrentPharmacy,
  setCurrentTeam,
  getApplicationData,
  removeAuth,
  setAuth,
  setPharmacies,
  getCompanyNames,
  updateLastLoginByEmail,
  updateLogoutByEmail,
  updateUserCurrentStatus,
} from "../dispatchers/appDispatchers";
import {
  getPharmacyClinicalServices,
  getPharmacyClinicalServicesCount,
  getPharmacyServices,
  setPharmacyServiceLoading,
  setCurrentServiceData,
  setCurrentNHSCount,
  setCurrentPrivateCount,
  setServiceTab,
  setShowCalender,
  getPharmaciesByCompanyId,
  setPharmacyServiceSidebarLoading,
  setPharmacyServicesAnalyticsLoading,
  getIdentityVerification,
  getPharmacyActions,
  getAgencyPharmacy,
} from "../dispatchers/pharmacyServiceDispatchers";
import {
  getPermissions,
  getTeamsRoleById,
  getTeamsRoles,
  setTeamLoading,
} from "../dispatchers/teamDispatchers";
import {
  getOrbitProfile,
  setCurrentOrbitLoading,
  getOrbitFollowUpServices,
  getOrbitPotentialEarnings,
  getOrbitOtherDetails,
  getOrbitUserSessions,
  getOrbitParkedServices,
  getOrbitMonthlyReport,
  getOrbitEarnings,
  setIsMonthlyReportLoading,
  setIsOrbitEarningLoading,
  setIsUserSessionLoading,
  setIsPotentialEarningLoading,
  setIsFollowupLoading,
  setIsOtherDetailsLoading,
  setIsActiveServicesLoading,
  getOrbitActiveServices,
  addOrbitSession,
  setCurrentTotalPrice,
  setCurrentOrbitAnimation,
  setCurrentTimeActive,
  getPaymentDetails,
  getOrbitTeamByCompanyId,
  getOrbitAgencyUsers,
  getOrbitAgencyDetails,
  getOrbitAgencyOtherDetails,
  getOrbitAgencyPharmacies,
  getOrbitAgencyOtherPharmacies,
  getOrbitAgencyMonthlyReport,
  getOrbitServicesWorkPlanner,
  getOrbitPharmanautsWorkPlanner,
  getOrbitWorkPlanner,
  getOthersAvailable,
} from "../dispatchers/orbitDispatchers";
import RolesAddNew from "../components/SettingsOptions/Teams/components/Roles/components/RolesAddNew/RolesAddNew";
import {
  getAllGenerals,
  setGeneralLoading,
} from "../dispatchers/generalDispatchers";
import {
  getBillingContact,
  setBillingLoading,
} from "../dispatchers/billingDispatchers";
import setServiceNms from "../Model/Nms/setServiceNms";
import setPrescriptionItemChecks from "../Model/PrescriptionItemChecks/setPrescriptionItemChecks";
import setPrescriptionChecks from "../Model/PrescriptionChecks/setPrescriptionChecks";
import NmsConsent from "../pages/NmsConsent/NmsConsent";
import NewMedicineService from "../pages/NmsConsent/components/NewMedicineService";
import Abort from "../pages/NmsConsent/components/Abort";
import MoreInfo from "../pages/NmsConsent/components/MoreInfo";
import PersonalInfo from "../pages/NmsConsent/components/PersonalInfo";
import MakeBooking from "../pages/NmsConsent/components/MakeBooking";
import SuccessPage from "../pages/NmsConsent/components/SuccessPage";
import ProfessionalDetails from "../components/OrbitSettings/ProfessionalDetails/ProfessionalDetails";
import ComplianceDocuments from "../components/OrbitSettings/ComplianceDocuments/ComplianceDocuments";
import {
  getAllTemplates,
  getEmailsAndSmsTemplates,
  setTemplatesLoading,
} from "../dispatchers/templateDispatchers";
import AddNewCustomTemplate from "../components/SettingsOptions/Templates/components/Consultation/AddNewCustomTemplate";
import {
  setMemberLoading,
  getmembers,
  getServices,
  getMemberById,
  getOrbitAgencyServicesByCompanyId,
} from "../dispatchers/memberDispatchers";
import Availability from "../components/OrbitSettings/Availability/Availability";
import ChangePassword from "../components/OrbitSettings/ChangePassword/ChangePassword";
import PaymentDetails from "../components/OrbitSettings/PaymentDetails/PaymentDetails";
import AccreditationsSkills from "../components/OrbitSettings/AccreditationsSkills/AccreditationsSkills";
import {
  getServiceTypeById,
  getServicesTypes,
  setCurrentType,
  getServicesByCompany,
} from "../dispatchers/typeDispatchers";
import TypesUpdate from "../components/SettingsOptions/Services/components/Types/components/TypesUpdate/TypesUpdate";
import TypesAddNew from "../components/SettingsOptions/Services/components/Types/components/TypesAddNew/TypesAddNew";
import { getServicesByPharmacyId } from "../dispatchers/locationDispatchers";
import {
  getAllConsentData,
  setConsentLoading,
} from "../dispatchers/consentDispatchers";
import {
  getUser,
  getEmailByInvitationCode,
  getUserForOrbit,
  setSignupForm,
  setOrbitGPHC,
  setYotiSteps,
  setProcessingApp,
  setEmailVerification,
  setOnboardingSuccess,
} from "../dispatchers/onboardingVerificationDsipatchers";
import { stageTypes } from "../enum/stageType";
import OnboardingVerification from "../components/OnboardingVerification/OnboardingVerification";
import InviteMembers from "../components/InviteMembers/InviteMembers";
import UserSignup from "../components/UserSignup/UserSignup";
import OnboardingResetPassword from "../components/OnboardingResetPassword/OnboardingResetPassword";
import SelectionScreen from "../components/SettingsOptions/SelectionScreen/SelectionScreen";
import moment from "moment";
import DifferentOrganization from "../components/DifferentOrganization/DifferentOrganization";
import setAddSession from "../Model/Orbit/setAddSession";
import ServiceTypes from "../components/SettingsOptions/Services/components/Types/ServiceTypes";
import EmailAndSms from "../components/SettingsOptions/EmailAndSms/EmailAndSms";
import UpdateEmailTemplate from "../components/SettingsOptions/EmailAndSms/component/UpdateEmailTemplate";
import AddTemplate from "../components/SettingsOptions/EmailAndSms/component/AddTemplate";
import PrivateService from "../pages/PrivateService/PrivateService";
import { setResponseDTOStagesData } from "../features/NmsServiceSlice/NmsServiceSlice";
import { element } from "prop-types";
import SignUp from "../pages/Dashboard/SignUp";
import { checkUserPermissions } from "../utils/checkUserPermissions";
import { accessOwner } from "../data/constantStrings";
import ConsultationForm from "../pages/PrivateService/components/components/ConsultationForm";
import setCCToolTipData from "../Model/ClinicalChecksToolTip/setCCToolTipData";

export const useTitanApp = () => {
  // #region Hook Constants
  const store = useStore();
  const auth = useAuth0();
  const appState = useSelector((state) => state.app);
  const pharmacyServiceState = useSelector((state) => state.pharmacyService);
  const nmsServiceState = useSelector((state) => state.nmsService);
  const documentState = useSelector((state) => state.documentService);
  const { loginWithRedirect, getAccessTokenSilently, logout, user } =
    auth || {};
  const clincicalChecksState = useSelector((state) => state.clinicalChecks);
  const orbitState = useSelector((state) => state.orbit);
  const consentState = useSelector((state) => state.consent);
  const locationState = useSelector((state) => state.location);
  const generalsState = useSelector((state) => state.general);
  const memberState = useSelector((state) => state.member);
  const billingState = useSelector((state) => state.billing);
  const onboardingVerificationState = useSelector(
    (state) => state.onboardingVerification
  );

  const FeatureSearchTypes = {
    All: 0,
    Enabled: 1,
    Disabled: 2,
    AllByCompanyId: 3,
    EnabledByCompanyId: 4,
    DisabledBycompanyId: 5,
    Minimal: 6,
    MinimalWithKpis: 7,
    MinimalWithPatients: 8,
  };

  // #endregion

  // #region API getter/caller functions for components usage to dispatch REDUX extra reducers
  const getAuthToken = async () => {
    let newAuthToken = "";

    try {
      newAuthToken = await getAccessTokenSilently();
    } catch (error) {
      if (error?.error === "login_required") {
        localStorage.setItem("loginRequired", true);
        removeAuth();
        showInfo("Loading...", false);
        setTimeout(
          (loginWithRedirect) => {
            loginWithRedirect();
          },
          100,
          loginWithRedirect
        );
      } else {
        logError(error?.error || "You are unauthorized or blocked...");
        showError(error?.error || "You are unauthorized or blocked...");
      }
    }

    setAuth(newAuthToken);
    return newAuthToken;
  };
  // #endregion

  // #region Loader Handlers
  const onDashboardLoaded = () => {
    const currentAppState = store.getState().app;
    let redirectTo = null;
    const { isValidOrg, companyId, currentPharmacyId, features, isOrbitUser } =
      currentAppState || {};

    if (isOrbitUser === true) {
      redirectTo = new redirect(`/orbit`);
    } else if (isValidOrg === true) {
      const { ifMapEnabled, ifPatientListEnabled, ifPharmacyOverviewEnabled } =
        features || {};

      if (ifMapEnabled === false) {
        if (ifPharmacyOverviewEnabled === true) {
          redirectTo = new redirect(`/pharmacyoverview/${currentPharmacyId}`);
        } else if (ifPatientListEnabled === true) {
          redirectTo = new redirect(`/pharmacypatient/${currentPharmacyId}`);
        } else {
          redirectTo = new redirect(`/pharmacyservices/${currentPharmacyId}`);
        }
      }
    } else {
      redirectTo = new redirect(`/invitemembers`);
    }
    // else {
    //   // if (!companyId) {
    //   //   showError("Company not found or not associated with you account.");
    //   // } else if (
    //   //   !currentPharmacyId ||
    //   //   currentPharmacyId === "00000000-0000-0000-0000-000000000000"
    //   // ) {
    //   //   showError("Pharmacy not found or not associated with you account.");
    //   // }
    //   // throw new Error("Invalid org or company or pharmacy not found.");
    // }
    return redirectTo;
  };

  const onVerificationLoaded = (invitationCode) => {
    const currentAppState = store.getState().app;
    const { authToken } = currentAppState || {} || {};
    let redirectTo = null;
    if (authToken !== "") {
      redirectTo = new redirect(`/usersignup/${invitationCode}`);
    }
    return redirectTo;
  };

  const onSelectionScreenLoaded = () => {
    let redirectTo = null;
    const storedOption = localStorage.getItem("selectedOption");
    if (storedOption === "orbit") {
      redirectTo = new redirect(`/orbit`);
    } else if (storedOption === "dashboard") {
      redirectTo = null;
    } else {
      redirectTo = new redirect(`/selectionScreen`);
    }
    return redirectTo;
  };

  const onOrbitLoaded = () => {
    let redirectTo = null;
    redirectTo = new redirect("/");
    return redirectTo;
  };

  const onPharmacyOverviewLoaded = () => {
    const currentAppState = store.getState().app;
    let redirectTo = null;
    const { isValidOrg, companyId, currentPharmacyId, features } =
      currentAppState || {};
    if (isValidOrg === true) {
      const { ifPatientListEnabled, ifPharmacyOverviewEnabled } =
        features || {};

      if (ifPharmacyOverviewEnabled === false) {
        if (ifPatientListEnabled === true) {
          redirectTo = new redirect(`/pharmacypatient/${currentPharmacyId}`);
        } else {
          redirectTo = new redirect(`/pharmacyservices/${currentPharmacyId}`);
        }
      }
    } else {
      if (!companyId) {
        showError("Company not found or not associated with you account.");
      } else if (
        !currentPharmacyId ||
        currentPharmacyId === "00000000-0000-0000-0000-000000000000"
      ) {
        showError("Pharmacy not found or not associated with you account.");
      }
      throw new Error("Invalid org or company or pharmacy not found.");
    }

    return redirectTo;
  };

  const onPharmacyPatientLoaded = () => {
    const currentAppState = store.getState().app;
    let redirectTo = null;
    const { isValidOrg, companyId, currentPharmacyId, features } =
      currentAppState || {};
    if (isValidOrg === true) {
      const { ifPatientListEnabled } = features || {};

      if (ifPatientListEnabled === false) {
        redirectTo = new redirect(`/pharmacyservices/${currentPharmacyId}`);
      }
    } else {
      if (!companyId) {
        showError("Company not found or not associated with you account.");
      } else if (
        !currentPharmacyId ||
        currentPharmacyId === "00000000-0000-0000-0000-000000000000"
      ) {
        showError("Pharmacy not found or not associated with you account.");
      }
      throw new Error("Invalid org or company or pharmacy not found.");
    }

    return redirectTo;
  };

  const onPharmacyServiceLoaded = () => {
    const currentAppState = store.getState().app;
    const { isValidOrg, companyId, currentPharmacyId } = currentAppState || {};
    if (isValidOrg === false) {
      if (!companyId) {
        showError("Company not found or not associated with you account.");
      } else if (
        !currentPharmacyId ||
        currentPharmacyId === "00000000-0000-0000-0000-000000000000"
      ) {
        showError("Pharmacy not found or not associated with you account.");
      }
      throw new Error("Invalid org or company or pharmacy not found.");
    }

    return null;
  };

  const teamAndPharmacyHandler = (redirectTo, data, params) => {
    let result = false;
    if (redirectTo === null && data && params.pharmacyId) {
      const { teams, user } = data || {};
      let teamIndex = 0;
      if (user?.role !== null) {
        teamIndex = teams?.findIndex((team) => {
          const result = team.pharmacies?.findIndex((pharmacy) => {
            return pharmacy.teamPharmacyID === params.pharmacyId;
          });

          return result >= 0;
        });
      } else {
        teamIndex = teams?.findIndex(
          (eachTeam) => eachTeam.isGlobalView === true
        );
        if (teamIndex === -1) {
          teamIndex = teams?.findIndex((team) => {
            const result = team.pharmacies?.findIndex(
              (pharmacy) => pharmacy.teamPharmacyID === params.pharmacyId
            );
            return result >= 0;
          });
        }
      }
      const teamFound = teamIndex >= 0 ? teams[teamIndex] : null;
      if (teamFound) {
        setCurrentTeam(teamFound);
        const pharmacyIndex = teamFound?.pharmacies?.findIndex(
          (pharmacy) => pharmacy.teamPharmacyID === params.pharmacyId
        );
        const pharmacyFound =
          pharmacyIndex >= 0 ? teamFound?.pharmacies[pharmacyIndex] : null;
        if (pharmacyFound) {
          setCurrentPharmacy(pharmacyFound);
          result = true;
        }
      }
      if (user?.role !== null) {
        let allPharmacies = [];
        teams?.map((team) => {
          team?.pharmacies.map((pharmacy) => {
            if (
              !allPharmacies.some(
                (phar) => phar.teamPharmacyID === pharmacy.teamPharmacyID
              )
            ) {
              allPharmacies = [...allPharmacies, pharmacy];
            }
          });
        });
        setPharmacies(allPharmacies);
      }
    }
    return result;
  };

  const calculateServiceData = async () => {
    const currentPharmacyServiceState = store.getState().pharmacyService;
    const mergedNamesandCounts =
      currentPharmacyServiceState?.pharmacyClincalServices?.map((obj1) => {
        const obj2 =
          currentPharmacyServiceState?.pharmacyClincalServicesCount?.find(
            (obj) => obj.serviceID === obj1.serviceID
          );
        return {
          ...obj1,
          ...obj2,
        };
      });

    setCurrentServiceData(mergedNamesandCounts);
    const NHSServicesList = mergedNamesandCounts.filter(
      (obj) => obj.serviceType === 1
    );
    const totalNHS = NHSServicesList.reduce((acc, obj) => acc + obj.total, 0);
    const privateServicesList = mergedNamesandCounts.filter(
      (obj) => obj.serviceType === 2
    );
    const totalPrivate = privateServicesList.reduce(
      (acc, obj) => acc + obj.total,
      0
    );

    const servicesTotal = totalNHS + totalPrivate;
    setCurrentNHSCount(totalNHS);
    setCurrentPrivateCount(totalPrivate);
    // setTotalCount(servicesTotal);
  };

  const setStageAndTabValues = (
    service,
    clinicalServiceType,
    clinicalServiceIdParam
  ) => {
    let StageIndexForTab,
      stageNo = 0;
    setCurrentStepNoNms(service.stages[stageNo]?.metaDataList?.length || 0);
    const stages = service?.stages;

    for (let index = 0; index < stages?.length; index++) {
      const stage = stages[index];

      if (stage.clinicalServiceStageStatus !== 2) {
        StageIndexForTab = index;
        if (index > 1) {
          setCurrentStepNoNms(1);
        }
        setCurrentstageNoNms(index);
        break;
      }
    }
    const model = new setServiceNms(
      service,
      StageIndexForTab,
      null,
      null,
      false,
      clinicalServiceType,
      null,
      null,
      null,
      null,
      "",
      "00000000-0000-0000-0000-000000000000",
      null
    );
    setCurrentNmsModel(model);
    if (clinicalServiceIdParam !== null) {
      setCurrentvalueNms(StageIndexForTab);
    }
  };

  const onSettingsLoaded = () => {
    const currentAppState = store.getState().app;
    let redirectTo = null;
    const { isValidOrg, companyId, currentPharmacyId, features, isOrbitUser } =
      currentAppState || {};

    if (isOrbitUser === true) {
      redirectTo = new redirect(`/orbit`);
    } else if (isValidOrg === true) {
      const {
        ifMapEnabled,
        ifPatientListEnabled,
        ifPharmacyOverviewEnabled,
        ifSettingEnabled,
        ifSettingMinimal,
      } = features || {};

      if (ifSettingEnabled === false) {
        if (ifMapEnabled === false) {
          if (ifPharmacyOverviewEnabled === true) {
            redirectTo = new redirect(`/pharmacyoverview/${currentPharmacyId}`);
          } else if (ifPatientListEnabled === true) {
            redirectTo = new redirect(`/pharmacypatient/${currentPharmacyId}`);
          } else {
            redirectTo = new redirect(`/pharmacyservices/${currentPharmacyId}`);
          }
        } else {
          redirectTo = new redirect("/");
        }
      } else if (ifSettingMinimal === true) {
        redirectTo = new redirect("/settings/general");
      }
    } else {
      if (!companyId) {
        showError("Company not found or not associated with you account.");
      } else if (
        !currentPharmacyId ||
        currentPharmacyId === "00000000-0000-0000-0000-000000000000"
      ) {
        showError("Pharmacy not found or not associated with you account.");
      }
      throw new Error("Invalid org or company or pharmacy not found.");
    }
    return redirectTo;
  };

  const hasPharmacistPermission = (permissions) => {
    return permissions.some(
      (permission) =>
        permission?.permissionName?.trim()?.toLowerCase() === "pharmacist"
    );
  };
  // #endregion

  // #region Loaders for APIs call
  const dashboardLoader = async () => {
    setAppLoading(true);
    let appData = null;
    try {
      const errorHandler = (error) => {
        logError(error);
        setAppLoading(false);
        return error;
      };

      const finallyHandler = () => {
        setAppLoading(false);
      };
      const appDataHandler = async (data) => {
        if (data != null) {
          return await getUser("");
        } else {
          setPharmacyServiceLoading(false);
          setAppLoading(false);
        }
      };

      const getUserDataHandler = (data) => {
        setAppLoading(false);
        if (data == null) {
          const redirectTo = onVerificationLoaded("orbit");
          return redirectTo;
        } else {
          const { isVerified, invitationCode, newCompanyID, accountType } =
            data || {};
          const loginRequired = localStorage.getItem("loginRequired");
          if (loginRequired === "true") {
            updateUserCurrentStatus(2);
            updateLastLoginByEmail()
              .then(() => {
                localStorage.setItem("loginRequired", false);
              })
              .catch((error) => {
                console.error("Error updating last login:", error);
              });
          }
          if (accountType === 3) {
            const redirectTo = onSelectionScreenLoaded();
            return redirectTo;
          }
          if (newCompanyID !== null) {
            const redirectTo = new redirect(`/neworganization`);
            return redirectTo;
          }
          if (isVerified === null || isVerified === false) {
            const redirectTo = onVerificationLoaded(invitationCode);
            return redirectTo;
          } else {
            const redirectTo = onDashboardLoaded();
            if (redirectTo !== null) {
              setAppLoading(true);
              return redirectTo;
            }
          }
        }

        return data;
      };

      const authTokenHandler = (authToken) => {
        if (authToken) {
          return getApplicationData(FeatureSearchTypes.All);
        } else {
          setPharmacyServiceLoading(false);
          setAppLoading(false);
        }
      };

      appData = getAuthToken()
        .then(authTokenHandler)
        .then(appDataHandler)
        .then(getUserDataHandler)
        .catch(errorHandler)
        .finally(finallyHandler);
    } catch (error) {
      logError(error);
      appData = null;
      setAppLoading(false);
    }
    return defer({ appData });
  };

  const signupLoader = async ({ params }) => {
    setAppLoading(true);
    let appData = null;
    try {
      const errorHandler = (error) => {
        logError(error);
        setAppLoading(false);
        return error;
      };
      const finallyHandler = () => {
        setAppLoading(false);
      };
      const appDataHandler = async () => {
        return await getEmailByInvitationCode(params.code);
      };
      const userSignUpHandler = async () => {
        const currentonboardingVerificationState =
          store.getState().onboardingVerification;
        const { userData } = currentonboardingVerificationState;
        if (userData?.isVerified === false) {
          if (userData?.role === null) {
            setSignupForm(true);
          } else {
            if (userData?.role?.permissions?.length > 0) {
              if (hasPharmacistPermission(userData?.role?.permissions)) {
                if (
                  userData?.gphcNumber === "" ||
                  userData?.gPHCNumber === null
                ) {
                  setOrbitGPHC(true);
                  setSignupForm(false);
                  setYotiSteps(false);
                  setProcessingApp(false);
                  setEmailVerification(false);
                  setOnboardingSuccess(false);
                } else {
                  getIdentityVerification().then((data) => {
                    setOrbitGPHC(false);
                    setSignupForm(false);
                    setYotiSteps(true);
                    setProcessingApp(false);
                    setEmailVerification(false);
                    return data;
                  });
                }
              } else {
                setSignupForm(false);
                setOnboardingSuccess(true);
              }
            } else {
              setSignupForm(false);
              setOnboardingSuccess(true);
            }
          }
        } else if (
          userData?.newCompanyID !== null ||
          userData?.newCompanyID !== ""
        ) {
          setSignupForm(true);
        }
      };

      appData = appDataHandler()
        .then(userSignUpHandler)
        .catch(errorHandler)
        .finally(finallyHandler);
    } catch (error) {
      logError(error);
      appData = null;
      setAppLoading(false);
    }
    return defer({ appData });
  };

  const pharmacyOverviewTabLoader = async ({ params }) => {
    setTabLoading(true);
    let appData = null;
    try {
      const appDataHandler = (data) => {
        const redirectTo = onPharmacyOverviewLoaded();
        setCurrentTeam(null);
        setCurrentPharmacy(null);
        const isteamAndPharmacyHandled = teamAndPharmacyHandler(
          redirectTo,
          data,
          params
        );
        isteamAndPharmacyHandled === true && setAppLoading(false);
        setTabLoading(false);

        return redirectTo !== null ? redirectTo : data;
      };

      const authTokenHandler = (authToken) => {
        if (authToken) {
          return getApplicationData(FeatureSearchTypes.MinimalWithKpis);
        } else {
          setPharmacyServiceLoading(false);
          setAppLoading(false);
          setTabLoading(false);
        }
        return null;
      };
      const errorHandler = (error) => {
        logError(error);
        setAppLoading(false);
        setTabLoading(false);
        return error;
      };
      appData = getAuthToken()
        .then(authTokenHandler)
        .then(appDataHandler)
        .catch(errorHandler);
    } catch (error) {
      logError(error);
      appData = null;
      setAppLoading(false);
      setTabLoading(false);
    }

    return defer({ appData });
  };

  const pharmacyPatientTabLoader = async ({ params }) => {
    setTabLoading(true);
    let appData = null;
    try {
      const appDataHandler = (data) => {
        const redirectTo = onPharmacyPatientLoaded();
        setCurrentTeam(null);
        setCurrentPharmacy(null);
        const isteamAndPharmacyHandled = teamAndPharmacyHandler(
          redirectTo,
          data,
          params
        );
        isteamAndPharmacyHandled === true && setAppLoading(false);
        setTabLoading(false);
        return redirectTo !== null ? redirectTo : data;
      };

      const authTokenHandler = (authToken) => {
        if (authToken) {
          return getApplicationData(FeatureSearchTypes.MinimalWithPatients);
        } else {
          setPharmacyServiceLoading(false);
          setAppLoading(false);
          setTabLoading(false);
        }
        return null;
      };
      const errorHandler = (error) => {
        logError(error);
        setAppLoading(false);
        setTabLoading(false);
        return error;
      };
      appData = getAuthToken()
        .then(authTokenHandler)
        .then(appDataHandler)
        .catch(errorHandler);
    } catch (error) {
      logError(error);
      appData = null;
      setAppLoading(false);
      setTabLoading(false);
    }

    return defer({ appData });
  };

  const pharmacyServicesTabLoader = async ({ params }) => {
    setTabLoading(true);
    setPharmacyServiceLoading(true);
    setPharmacyServiceSidebarLoading(true);
    setPharmacyServicesAnalyticsLoading(true);
    setServiceTab(0);
    setShowCalender(false);
    let appData = null;

    try {
      const appDataHandler = (data) => {
        let result = null;
        const redirectTo = onPharmacyServiceLoaded();
        setCurrentTeam(null);
        setCurrentPharmacy(null);

        const isteamAndPharmacyHandled = teamAndPharmacyHandler(
          redirectTo,
          data,
          params
        );
        if (isteamAndPharmacyHandled === true) {
          result = getServiceData(params.pharmacyId);
        }

        return redirectTo !== null ? redirectTo : result;
      };

      const authTokenHandler = (authToken) => {
        if (authToken) {
          return getApplicationData();
        } else {
          setPharmacyServiceLoading(false);
          setAppLoading(false);
          setTabLoading(false);
        }
        return null;
      };

      const pharmacyServiceHandler = (data) => {
        setAppLoading(false);
        setTabLoading(false);
        return data;
      };

      const errorHandler = (error) => {
        logError(error);
        setPharmacyServiceLoading(false);
        setAppLoading(false);
        setTabLoading(false);
        return error;
      };
      appData = getAuthToken()
        .then(authTokenHandler)
        .then(appDataHandler)
        .then(pharmacyServiceHandler)
        .catch(errorHandler);
    } catch (error) {
      logError(error);
      appData = null;
      setPharmacyServiceLoading(false);
      setAppLoading(false);
      setTabLoading(false);
    }

    return defer({ appData });
  };

  const getServiceData = async (pharmacyId) => {
    var pharmacyClinicalService = await getPharmacyClinicalServices(pharmacyId);
    var pharmacyAction = await getPharmacyActions(pharmacyId);
    var pharmacyCount = await getPharmacyClinicalServicesCount(pharmacyId);
    var pharmacyAgency = await getAgencyPharmacy(pharmacyId);
    await calculateServiceData();
    var pharmacyServices = await getPharmacyServices(pharmacyId, 0, 0, false);
    return pharmacyServices;
  };

  const getClinicalChecksLoader = async ({ params }) => {
    setAppLoading(true);
    let appData = null;

    try {
      const authTokenHandler = (authToken) => {
        if (authToken) {
          return getApplicationData();
        } else {
        }
        return null;
      };

      const appDataHandler = (data) => {
        const currentAppState = store.getState().app;
        const { currentPharmacyId, currentTeamId } = currentAppState || {};
        const currentClinicalChecksState = store.getState().clinicalChecks;
        const { incomingIds } = currentClinicalChecksState || {};

        const pharmacyId =
          params?.pharmacyId === "00000000-0000-0000-0000-000000000000"
            ? currentPharmacyId
            : params?.pharmacyId;
        return getClinicalChecksResult(
          incomingIds[0]?.incomingID,
          incomingIds[0]?.pharmacyID
        );
      };

      const errorHandler = (error) => {
        logError(error);
        setAppLoading(false);
        return error;
      };
      appData = getAuthToken()
        .then(authTokenHandler)
        .then(appDataHandler)
        .catch(errorHandler);
    } catch (error) {
      logError(error);
      appData = null;
      setAppLoading(false);
    }

    return defer({ appData });
  };

  const getClinicalChecksResult = async (
    prescriptionIncomingId,
    pharmacyID
  ) => {
    const prescriptionData = await getPrescriptionDataByIncomingId(
      prescriptionIncomingId,
      pharmacyID
    );
    setPatientImage([]);
    if (prescriptionData && prescriptionIncomingId != null) {
      setIsPrescriptionItemToolTip([]);
      await getPatientMedicationData();
      await getPrescriptionItemCheckData();
      await getPrescriptionChecksData();
      await getClinicalChecksPatientImageData();
      for (const item of prescriptionData?.prescriptionGetModel
        ?.prescriptionItems) {
        const CCOrderTooltipBody = new setCCToolTipData({
          patientId: prescriptionData?.prescriptionGetModel?.patientId,
          prescriptionId:
            prescriptionData?.prescriptionGetModel?.prescriptionId,
          prescriptionIncomingId:
            prescriptionData?.prescriptionGetModel?.prescriptionIncomingId,
          prescribedItemId: item?.prescribedItemId,
          prescriptionItemId: item?.prescriptionItemId,
          epsLineItem: item?.epsLineItem,
          quantity: item?.quantity,
          hasFlavours: false,
          dosageText: item?.dosageText,
        });
        await setCCOrderItemToolTip({
          CCOrderTooltipBody,
          pharmacyID,
        });
      }
    }
    setAppLoading(false);
  };
  const getPrescriptionDataByIncomingId = async (
    prescriptionIncomingId,
    pharmacyID
  ) => {
    const data = getPrescriptionByIncomingId({
      prescriptionIncomingId,
      pharmacyID,
    });
    return data;
  };
  const getPatientMedicationData = async () => {
    const currentClinicalChecksState = store.getState().clinicalChecks;
    const { clinicalChecksPharmacy, patientId } =
      currentClinicalChecksState || {};

    const { pharmacyID } = clinicalChecksPharmacy || {
      pharmacyID: "00000000-0000-0000-0000-000000000000",
    };
    getPatientMedication({ patientId, pharmacyID });
  };
  const getPrescriptionItemCheckData = async () => {
    const currentClinicalChecksState = store.getState().clinicalChecks;
    const { clinicalChecksPharmacy, prescriptions } =
      currentClinicalChecksState || {};
    const { pharmacyID } = clinicalChecksPharmacy || {
      pharmacyID: "00000000-0000-0000-0000-000000000000",
    };
    const prescriptionItemChecksBody = new setPrescriptionItemChecks(
      prescriptions,
      0,
      "",
      "",
      false,
      ""
    ).obj;
    getPrescriptionItemCheck({
      prescriptionItemChecksBody,
      pharmacyID,
    });
  };
  const getPrescriptionChecksData = async () => {
    const currentClinicalChecksState = store.getState().clinicalChecks;
    const { clinicalChecksPharmacy, prescriptions } =
      currentClinicalChecksState || {};
    const { pharmacyID } = clinicalChecksPharmacy || {
      pharmacyID: "00000000-0000-0000-0000-000000000000",
    };
    const prescriptionCheckBody = new setPrescriptionChecks(prescriptions, "");
    getPrescriptionChecks({
      prescriptionCheckBody,
      pharmacyID,
    });
  };

  const getClinicalChecksPatientImageData = async () => {
    const currentClinicalChecksState = store.getState().clinicalChecks;
    const { prescriptions, clinicalChecksPharmacy } =
      currentClinicalChecksState || {};
    const { pharmacyID } = clinicalChecksPharmacy || {
      pharmacyID: "00000000-0000-0000-0000-000000000000",
    };
    const prescriptionImages = prescriptions?.prescriptionImages;
    const resultArray = [];
    if (prescriptionImages) {
      for (const imageValue of prescriptionImages) {
        const result = await getPatientImage({
          imageValue,
          pharmacyID,
        });
        resultArray.push(`data:image/png;base64, ${result}`);
      }
      if (resultArray?.length > 0) {
        setPatientImage(resultArray);
      } else {
        setPatientImage([]);
      }
    }
  };

  const rolesAndPermissionsLoader = async ({ params }) => {
    setTeamLoading(true);
    let teamRoles = null;
    let teamPermissions = null;
    let appData = null;

    const finallyHandler = () => {
      setTeamLoading(false);
      setAppLoading(false);
    };

    const errorHandler = (error) => {
      if (error instanceof Response && error?.status === 302) {
        throw error;
      }
      logError(error);
      setTeamLoading(false);
      setAppLoading(false);
      return error;
    };

    const appDataHandler = async (data) => {
      if (data?.companyID) {
        const { companyID } = data || {
          companyID: "00000000-0000-0000-0000-000000000000",
        };

        setAppLoading(false);
        const redirectTo = onSettingsLoaded();
        if (redirectTo !== null) {
          setAppLoading(true);
          return redirectTo;
        }

        try {
          teamRoles = await getTeamsRoles(companyID);
          teamPermissions = await getPermissions();
          return { teamRoles, teamPermissions };
        } catch (error) {
          logError(error);
          setTeamLoading(false);
          setAppLoading(false);
          return error;
        }
      }

      return data;
    };
    appData = getApplicationData()
      .then(appDataHandler)
      .catch(errorHandler)
      .finally(finallyHandler);

    return defer({ appData });
  };

  const rolesByIdLoader = async ({ params }) => {
    const { roleId } = params || {};
    setTeamLoading(true);
    let appData = null;

    const finallyHandler = () => {
      setTeamLoading(false);
      setAppLoading(false);
    };

    const errorHandler = (error) => {
      console.log(error);
      if (error instanceof Response && error?.status === 302) {
        throw error;
      }
      logError(error);
      setTeamLoading(false);
      setAppLoading(false);
      return error;
    };

    const appDataHandler = async (data) => {
      if (data?.companyID) {
        setAppLoading(false);
        const redirectTo = onSettingsLoaded();
        if (redirectTo !== null) {
          setAppLoading(true);
          return redirectTo;
        }

        try {
          const teamRoles = await getTeamsRoleById(roleId);
          const teamPermissions = await getPermissions();
          return { data, teamRoles, teamPermissions };
        } catch (error) {
          logError(error);
          setTeamLoading(false);
          setAppLoading(false);
          return error;
        }
      }

      return data;
    };
    appData = getApplicationData()
      .then(appDataHandler)
      .catch(errorHandler)
      .finally(finallyHandler);

    return defer({ appData });
  };

  const rolesAddNewLoader = async ({ params }) => {
    setTeamLoading(true);
    let appData = null;

    const finallyHandler = () => {
      setTeamLoading(false);
      setAppLoading(false);
    };

    const errorHandler = (error) => {
      console.log(error);
      if (error instanceof Response && error?.status === 302) {
        throw error;
      }
      logError(error);
      setTeamLoading(false);
      setAppLoading(false);
      return error;
    };

    const appDataHandler = async (data) => {
      if (data?.companyID) {
        setAppLoading(false);
        const redirectTo = onSettingsLoaded();
        if (redirectTo !== null) {
          setAppLoading(true);
          return redirectTo;
        }

        try {
          const teamPermissions = await getPermissions();
          return { data, teamPermissions };
        } catch (error) {
          logError(error);
          setTeamLoading(false);
          setAppLoading(false);
          return error;
        }
      }

      return data;
    };
    appData = getApplicationData()
      .then(appDataHandler)
      .catch(errorHandler)
      .finally(finallyHandler);

    return defer({ appData });
  };

  const NmsLoader = async ({ params }) => {
    setCurrentIsNmsLoading(true);
    setCurrentIsNmsTimelineLoading(true);
    setCurrentIsNmsPatientLoading(true);
    setCurrentIsNmsPrescriptionLoading(true);
    setCurrentIsServiceNotesLoading(true);
    setCurrentIsPatientNmsTelephoneLoading(true);
    setAppLoading(true);
    let appData = null;
    try {
      const authTokenHandler = (authToken) => {
        if (authToken) {
          return getApplicationData();
        } else {
        }
        return null;
      };

      const appDataHandler = (data) => {
        let currentAppState = store.getState().app;
        const { isOrbitUser, isOrbitSwitch } = currentAppState || {};

        if (isOrbitUser || isOrbitSwitch) {
          return getPharmacyIdForOrbitUser();
        } else {
          return null;
        }
      };

      const checkOrbitUserHandler = (data) => {
        const currentOrbitState = store.getState().orbit;
        const { isTestMode } = currentOrbitState || false;
        if (params?.serviceId === "0") {
          let currentAppState = store.getState().app;
          const { currentTeam, isOrbitUser, isOrbitSwitch } =
            currentAppState || {};
          if (data != null) {
            setCurrentPharmacy(data);
            currentAppState = store.getState().app;
            const currentSessionId = localStorage.getItem("currentSessionId");
            return getClinicalServiceFromOrbit({
              serviceCode: 1,
              sessionId: currentSessionId,
              isTestMode: isTestMode,
            });
          } else {
            const randomIndex = Math.floor(Math.random() * currentTeam?.length);
            const randomPharmacy = currentTeam?.pharmacies[randomIndex];
            setCurrentPharmacy(randomPharmacy);
            currentAppState = store.getState().app;
            const currentSessionId = localStorage.getItem("currentSessionId");
            return getClinicalServiceFromOrbit({
              serviceCode: 1,
              sessionId: currentSessionId,
              isTestMode: isTestMode,
            });
          }
        } else {
          return getClinicalServiceByClinicalServiceId(params.serviceId);
        }
      };

      const ClinicalServiceHandler = (service) => {
        if (service?.clinicalServiceBuilderResponseDTO) {
          getClinicalServiceResult(
            service,
            params?.serviceId === "0"
              ? service?.clinicalServiceBuilderResponseDTO?._id
              : params?.serviceId
          );
        } else {
          return null;
        }
      };
      const errorHandler = (error) => {
        logError(error);
        setAppLoading(false);
        setCurrentIsNmsLoading(false);
        setCurrentIsNmsTimelineLoading(false);
        setCurrentIsNmsPatientLoading(false);
        setCurrentIsNmsPrescriptionLoading(false);
        setCurrentIsPatientNmsTelephoneLoading(false);
        return error;
      };

      appData = getAuthToken()
        .then(authTokenHandler)
        .then(appDataHandler)
        .then(checkOrbitUserHandler)
        .then(ClinicalServiceHandler)
        .catch(errorHandler);
    } catch (error) {
      logError(error);
      appData = null;
      setAppLoading(false);
    }

    return defer({ appData });
  };

  const privateServiceLoader = async ({ params }) => {
    setCurrentIsNmsLoading(true);
    setCurrentIsNmsTimelineLoading(true);
    setCurrentIsNmsPatientLoading(true);
    setCurrentIsNmsPrescriptionLoading(true);
    setCurrentIsServiceNotesLoading(true);
    setCurrentIsPatientNmsTelephoneLoading(true);
    setAppLoading(true);
    let appData = null;
    try {
      const authTokenHandler = (authToken) => {
        if (authToken) {
          return getApplicationData();
        } else {
        }
        return null;
      };

      const appDataHandler = (data) => {
        let currentAppState = store.getState().app;
        const { isOrbitUser, isOrbitSwitch } = currentAppState || {};

        if (isOrbitUser || isOrbitSwitch) {
          return getPharmacyIdForOrbitUser();
        } else {
          return null;
        }
      };

      const checkOrbitUserHandler = async (data) => {
        const currentOrbitState = store.getState().orbit;
        const { isTestMode } = currentOrbitState || false;
        if (params?.serviceId === "0") {
          let currentAppState = store.getState().app;
          const { currentTeam, isOrbitUser, isOrbitSwitch } =
            currentAppState || {};
          if (data != null) {
            setCurrentPharmacy(data);
            currentAppState = store.getState().app;
            const currentSessionId = localStorage.getItem("currentSessionId");
            return getClinicalServiceFromOrbit({
              serviceCode: 1,
              sessionId: currentSessionId,
              isTestMode: isTestMode,
            });
          } else {
            const randomIndex = Math.floor(Math.random() * currentTeam?.length);
            const randomPharmacy = currentTeam?.pharmacies[randomIndex];
            setCurrentPharmacy(randomPharmacy);
            currentAppState = store.getState().app;
            const currentSessionId = localStorage.getItem("currentSessionId");
            return getClinicalServiceFromOrbit({
              serviceCode: 1,
              sessionId: currentSessionId,
              isTestMode: isTestMode,
            });
          }
        } else {
          try {
            const clinicalServiceData =
              await getClinicalServiceByClinicalServiceId(params.serviceId);
            getTimelineDataForClinicalServiceId(params.serviceId);
            if (clinicalServiceData) {
              const stagesData =
                clinicalServiceData?.clinicalServiceBuilderResponseDTO.stages[0]
                  .template.templateSections[0].userControls;
              setCurrentResponseDTOStagesData(stagesData);
            }

            return clinicalServiceData;
          } catch (error) {
            console.error("Error fetching clinical service:", error);
          }
        }
      };

      const ClinicalServiceHandler = (service) => {
        if (service?.clinicalServiceBuilderResponseDTO) {
          getPrivateServiceResult(
            service,
            params?.serviceId === "0"
              ? service?.clinicalServiceBuilderResponseDTO?._id
              : params?.serviceId
          );
        } else {
          return null;
        }
      };
      const errorHandler = (error) => {
        logError(error);
        setAppLoading(false);
        setCurrentIsNmsLoading(false);
        setCurrentIsNmsTimelineLoading(false);
        setCurrentIsNmsPatientLoading(false);
        setCurrentIsNmsPrescriptionLoading(false);
        setCurrentIsPatientNmsTelephoneLoading(false);
        return error;
      };

      appData = getAuthToken()
        .then(authTokenHandler)
        .then(appDataHandler)
        .then(checkOrbitUserHandler)
        .then(ClinicalServiceHandler)
        .catch(errorHandler);
    } catch (error) {
      logError(error);
      appData = null;
      setAppLoading(false);
    }

    return defer({ appData });
  };

  const getMultiplePrescriptionImages = async (incomingIds, pharmacyID) => {
    const resultArray = [];
    var data = await getPrescriptionImagesByIncomingIds({
      incomingIds,
      pharmacyID,
    });
    if (data) {
      for (const imageValue of data) {
        resultArray.push(`data:image/png;base64, ${imageValue}`);
      }
      if (resultArray?.length > 0) {
        setCurrentArrayImg(resultArray);
      } else {
        setCurrentArrayImg([]);
      }
    }
    setCurrentIsNmsPrescriptionLoading(false);
  };
  const getClinicalServiceResult = (service, serviceId) => {
    let currentAppState = store.getState().app;
    const { isOrbitUser } = currentAppState || {};
    const { clinicalServiceBuilderResponseDTO, pharmacy, incomingIds } =
      service || {};

    const { pharmacyID } = pharmacy || {
      pharmacyID: clinicalServiceBuilderResponseDTO?.pharmacyID,
    };
    const { patientId, prescriptionIncomingId, telephoneNumber } =
      clinicalServiceBuilderResponseDTO || {
        patientId: "00000000-0000-0000-0000-000000000000",
        prescriptionIncomingId: "00000000-0000-0000-0000-000000000000",
        telephoneNumber: null,
      };
    const currentSessionId = localStorage.getItem("currentSessionId");
    if (isOrbitUser) {
      getTotalSkips({
        clinicalServiceId: serviceId,
        sessionId: currentSessionId,
      });
    }
    getFollowupDetailByClinicalServiceId(serviceId);
    getServiceNotesByClinicalServiceId(serviceId);
    getTimelineDataForClinicalServiceId(serviceId);

    if (telephoneNumber !== null && telephoneNumber !== "") {
      getPatientByTelephoneAndStageIndex(telephoneNumber, serviceId);
    } else {
      setCurrentPatientNMSData([]);
    }

    setCurrentArrayImg([]);
    if (incomingIds?.length > 0) {
      const resultArray = [];
      getMultiplePrescriptionImages(incomingIds, pharmacyID);
    }
    if (
      patientId !== null &&
      patientId !== "00000000-0000-0000-0000-000000000000"
    ) {
      const clinicalServiceId = serviceId;
      getTitanPatient({ pharmacyID, patientId, clinicalServiceId }).then(
        (data) => {
          prescriptionPatientImage(data);
        }
      );
    }
    setCurrentIsServiceNotesLoading(false);
    setCurrentIsNmsTimelineLoading(false);
  };
  const getPrivateServiceResult = (service, serviceId) => {
    let currentAppState = store.getState().app;
    const { isOrbitUser } = currentAppState || {};
    const { clinicalServiceBuilderResponseDTO, pharmacy } = service || {};
    const { pharmacyID } = pharmacy || {
      pharmacyID: clinicalServiceBuilderResponseDTO?.pharmacyID,
    };
    const { patientId, prescriptionIncomingId, telephoneNumber } =
      clinicalServiceBuilderResponseDTO || {
        patientId: "00000000-0000-0000-0000-000000000000",
        prescriptionIncomingId: "00000000-0000-0000-0000-000000000000",
        telephoneNumber: null,
      };
    const currentSessionId = localStorage.getItem("currentSessionId");
    getTravelInformation(serviceId);
    if (isOrbitUser) {
      getTotalSkips({
        clinicalServiceId: serviceId,
        sessionId: currentSessionId,
      });
    }
    if (telephoneNumber !== null && telephoneNumber !== "") {
      getPatientByTelephoneAndStageIndex(telephoneNumber, serviceId);
    } else {
      setCurrentPatientNMSData([]);
    }
  };
  const getFollowupDetailByClinicalServiceId = (clinicalServiceId) => {
    const currentNmsState = store.getState().nmsService;
    const { value } = currentNmsState || {};
    const stageType = stageTypes[value];
    getFollowUpByServiceIdAndServiceType(clinicalServiceId, "1", stageType);
  };

  const getPatientByTelephoneAndStageIndex = (telephone, serviceId) => {
    const currentNmsState = store.getState().nmsService;
    let currentAppState = store.getState().app;
    const { isOrbitUser } = currentAppState || {};
    const { value } = currentNmsState || {};
    const currentSessionId = localStorage.getItem("currentSessionId");
    getPatientByTelephoneAndStageIndexResult(
      telephone,
      value,
      serviceId,
      currentSessionId,
      isOrbitUser
    );
    setCurrentIsPatientNmsTelephoneLoading(false);
  };

  const getServiceNotesByClinicalServiceId = (clinicalServiceId) => {
    const currentNmsState = store.getState().nmsService;
    const { value } = currentNmsState || {};
    const stageNo = value;
    const stageType = stageTypes[stageNo];

    getServiceNotes({ clinicalServiceId, stageType });
  };

  const getTimelineDataForClinicalServiceId = (serviceId) => {
    let currentAppState = store.getState().app;
    const { isOrbitUser, isOrbitSwitch } = currentAppState;
    const currentNmsState = store.getState().nmsService;
    const { clinicalServiceId } = currentNmsState || {};
    getTimeline(isOrbitUser || isOrbitSwitch ? clinicalServiceId : serviceId);
  };
  const prescriptionImageData = async (data, pharmacyID) => {
    if (data) {
      const { prescriptionGetModel } = data || {};
      const { prescriptionImages } = prescriptionGetModel ?? [];
      const resultArray = [];
      if (prescriptionImages) {
        for (const imageValue of prescriptionImages) {
          const result = await getPatientImage({
            imageValue,
            pharmacyID,
          });
          resultArray.push(`data:image/png;base64, ${result}`);
        }
        if (resultArray?.length > 0) {
          setCurrentArrayImg(resultArray);
        } else {
          setCurrentArrayImg([]);
        }
      }
    }
    setCurrentIsNmsPrescriptionLoading(false);
  };

  const prescriptionPatientImage = async (patientData) => {
    if (patientData) {
      const currentNmsState = store.getState().nmsService;
      const { prescriptionImages, pharmacyID } = currentNmsState || {};
      const { patientGetModel } = patientData || {};
      const patientName = `${patientGetModel.title} ${patientGetModel.forename} ${patientGetModel.middleName} ${patientGetModel.surname}`;
      setCurrentPatientName(patientName);
    }
    setCurrentIsNmsPatientLoading(false);
  };

  const generalDetailsLoader = async () => {
    setGeneralLoading(true);
    let appData = null;

    const finallyHandler = () => {
      setGeneralLoading(false);
      setAppLoading(false);
    };

    const errorHandler = (error) => {
      console.log(error);
      if (error instanceof Response && error?.status === 302) {
        throw error;
      }
      logError(error);
      setGeneralLoading(false);
      setAppLoading(false);
      return error;
    };

    const appDataHandler = async (data) => {
      if (data?.companyID) {
        setAppLoading(false);
        const redirectTo = onSettingsLoaded();
        if (redirectTo !== null) {
          setAppLoading(true);
          return redirectTo;
        }

        try {
          const generalDetails = await getAllGenerals();
          return { data, generalDetails };
        } catch (error) {
          logError(error);
          setGeneralLoading(false);
          setAppLoading(false);
          return error;
        }
      }

      return data;
    };
    appData = getApplicationData()
      .then(appDataHandler)
      .catch(errorHandler)
      .finally(finallyHandler);

    return defer({ appData });
  };

  const billingDetailsLoader = async () => {
    setBillingLoading(true);
    let appData = null;

    const finallyHandler = () => {
      setBillingLoading(false);
      setAppLoading(false);
    };

    const errorHandler = (error) => {
      console.log(error);
      if (error instanceof Response && error?.status === 302) {
        throw error;
      }
      logError(error);
      setBillingLoading(false);
      setAppLoading(false);
      return error;
    };

    const appDataHandler = async (data) => {
      if (data?.user) {
        setAppLoading(false);
        const redirectTo = onSettingsLoaded();
        if (redirectTo !== null) {
          setAppLoading(true);
          return redirectTo;
        }

        try {
          const billingDetails = await getBillingContact();
          return { data, billingDetails };
        } catch (error) {
          logError(error);
          setBillingLoading(false);
          setAppLoading(false);
          return error;
        }
      }

      return data;
    };
    appData = getApplicationData()
      .then(appDataHandler)
      .catch(errorHandler)
      .finally(finallyHandler);

    return defer({ appData });
  };

  const membersLoader = async () => {
    setMemberLoading(true);
    let appData = null;

    const finallyHandler = () => {
      setMemberLoading(false);
      setAppLoading(false);
    };

    const errorHandler = (error) => {
      console.log(error);
      if (error instanceof Response && error?.status === 302) {
        throw error;
      }
      logError(error);
      setMemberLoading(false);
      setAppLoading(false);
      return error;
    };

    const appDataHandler = async (data) => {
      if (data?.companyID) {
        const { companyID } = data || {
          companyID: "00000000-0000-0000-0000-000000000000",
        };

        setAppLoading(false);
        const redirectTo = onSettingsLoaded();
        if (redirectTo !== null) {
          setAppLoading(true);
          return redirectTo;
        }

        try {
          const membersData = await getmembers(companyID);
          const rolesData = await getTeamsRoles(companyID);
          const servicesData = await getServices();
          return { data, membersData, rolesData, servicesData };
        } catch (error) {
          logError(error);
          setMemberLoading(false);
          setAppLoading(false);
          return error;
        }
      }

      return data;
    };
    appData = getApplicationData()
      .then(appDataHandler)
      .catch(errorHandler)
      .finally(finallyHandler);

    return defer({ appData });
  };

  const memberAddNewLoader = async ({ params }) => {
    setMemberLoading(true);
    let appData = null;

    const finallyHandler = () => {
      setMemberLoading(false);
      setAppLoading(false);
    };

    const errorHandler = (error) => {
      console.log(error);
      if (error instanceof Response && error?.status === 302) {
        throw error;
      }
      logError(error);
      setMemberLoading(false);
      setAppLoading(false);
      return error;
    };

    const appDataHandler = async (data) => {
      if (data?.companyID) {
        const companyID = data.companyID;
        setAppLoading(false);
        const redirectTo = onSettingsLoaded();
        if (redirectTo !== null) {
          setAppLoading(true);
          return redirectTo;
        }

        try {
          const teamPermissions = await getPermissions();
          const serviceData = await getOrbitAgencyServicesByCompanyId(
            companyID
          );
          return { data, teamPermissions, serviceData };
        } catch (error) {
          logError(error);
          setMemberLoading(false);
          setAppLoading(false);
          return error;
        }
      }

      return data;
    };
    appData = getApplicationData()
      .then(appDataHandler)
      .catch(errorHandler)
      .finally(finallyHandler);

    return defer({ appData });
  };

  const memberByIdLoader = async ({ params }) => {
    const { memberId } = params || {};
    setMemberLoading(true);
    let appData = null;

    const finallyHandler = () => {
      setMemberLoading(false);
      setAppLoading(false);
    };

    const errorHandler = (error) => {
      console.log(error);
      if (error instanceof Response && error?.status === 302) {
        throw error;
      }
      logError(error);
      setMemberLoading(false);
      setAppLoading(false);
      return error;
    };

    const appDataHandler = async (data) => {
      if (data?.companyID) {
        const companyID = data.companyID;
        setAppLoading(false);
        const redirectTo = onSettingsLoaded();
        if (redirectTo !== null) {
          setAppLoading(true);
          return redirectTo;
        }

        try {
          const teamMembers = await getMemberById(memberId);
          const teamPermissions = await getPermissions();
          const serviceData = await getOrbitAgencyServicesByCompanyId(
            companyID
          );
          return { data, teamMembers, teamPermissions, serviceData };
        } catch (error) {
          logError(error);
          setMemberLoading(false);
          setAppLoading(false);
          return error;
        }
      }

      return data;
    };
    appData = getApplicationData()
      .then(appDataHandler)
      .catch(errorHandler)
      .finally(finallyHandler);

    return defer({ appData });
  };

  const consultationLoader = async ({ params }) => {
    let allTemplates = null;
    let appData = null;

    const finallyHandler = () => {
      setAppLoading(false);
    };

    const errorHandler = (error) => {
      if (error instanceof Response && error?.status === 302) {
        throw error;
      }
      logError(error);
      setAppLoading(false);
      return error;
    };

    const appDataHandler = async (data) => {
      if (data?.companyID) {
        const { companyID } = data || {
          companyID: "00000000-0000-0000-0000-000000000000",
        };

        setAppLoading(false);
        const redirectTo = onSettingsLoaded();
        if (redirectTo !== null) {
          setAppLoading(true);
          return redirectTo;
        }

        try {
          allTemplates = await getAllTemplates(companyID);
          return { allTemplates };
        } catch (error) {
          logError(error);
          setAppLoading(false);
          return error;
        }
      }

      return data;
    };
    appData = getApplicationData()
      .then(appDataHandler)
      .catch(errorHandler)
      .finally(finallyHandler);

    return defer({ appData });
  };

  const addConsultationLoader = async ({ params }) => {
    let appData = null;

    const finallyHandler = () => {
      setAppLoading(false);
    };

    const errorHandler = (error) => {
      if (error instanceof Response && error?.status === 302) {
        throw error;
      }
      logError(error);
      setAppLoading(false);
      return error;
    };

    const appDataHandler = async (data) => {
      if (data?.companyID) {
        setAppLoading(false);
        const redirectTo = onSettingsLoaded();
        if (redirectTo !== null) {
          setAppLoading(true);
          return redirectTo;
        }
      }

      return data;
    };
    appData = getApplicationData()
      .then(appDataHandler)
      .catch(errorHandler)
      .finally(finallyHandler);

    return defer({ appData });
  };

  const typeLoader = async ({ params }) => {
    let allServicesTypes = null;
    let appData = null;

    const finallyHandler = () => {
      setAppLoading(false);
    };

    const errorHandler = (error) => {
      if (error instanceof Response && error?.status === 302) {
        throw error;
      }
      logError(error);
      setAppLoading(false);
      return error;
    };

    const appDataHandler = async (data) => {
      if (data) {
        const { companyID } = data || {
          companyID: "00000000-0000-0000-0000-000000000000",
        };
        setAppLoading(false);
        const redirectTo = onSettingsLoaded();
        if (redirectTo !== null) {
          setAppLoading(true);
          return redirectTo;
        }

        try {
          allServicesTypes = await getServicesByCompany(companyID);
          return { allServicesTypes };
        } catch (error) {
          logError(error);
          setAppLoading(false);
          return error;
        }
      }

      return data;
    };
    appData = getApplicationData()
      .then(appDataHandler)
      .catch(errorHandler)
      .finally(finallyHandler);

    return defer({ appData });
  };

  const typeAddNewLoader = async () => {
    let appData = null;

    const finallyHandler = () => {
      setAppLoading(false);
    };

    const errorHandler = (error) => {
      if (error instanceof Response && error?.status === 302) {
        throw error;
      }
      logError(error);
      setAppLoading(false);
      return error;
    };

    const appDataHandler = async (data) => {
      if (data) {
        setAppLoading(false);
        const redirectTo = onSettingsLoaded();
        if (redirectTo !== null) {
          setAppLoading(true);
          return redirectTo;
        }

        try {
          let allTemplates = [];
          let companyPharmacies = [];
          let newServiceType = null;
          if (data?.companyID) {
            newServiceType = {
              pharmacyIds: [],
              serviceModel: {
                isDefault: false,
                companyID: data?.companyID,
                serviceType: 2,
                serviceName: "",
                serviceDescription: "",
                serviceCharges: 0,
                serviceDuration: 0,
                serviceColor: "",
                notificationTemplates: [
                  {
                    myProperty: 0,
                    notificationTemplateType: 1,
                  },
                ],
                serviceCode: 0,
                isOnlineBooking: false,
              },
              clinicalServiceBuilder: {
                serviceName: "",
                stages: [],
                numberOfStages: 0,
                serviceType: 2,
                companyID: data?.companyID,
                isDefault: false,
                serviceID: "",
                serviceCode: 0,
              },
            };

            companyPharmacies = getPharmaciesByCompanyId(data?.companyID);
            allTemplates = getAllTemplates(data?.companyID);

            setCurrentType(newServiceType);
          }
          return { newServiceType, allTemplates, companyPharmacies };
        } catch (error) {
          logError(error);
          setAppLoading(false);
          return error;
        }
      }

      return data;
    };
    appData = getApplicationData()
      .then(appDataHandler)
      .catch(errorHandler)
      .finally(finallyHandler);

    return defer({ appData });
  };

  const typeByServiceIdLoader = async ({ params }) => {
    const { serviceId } = params || {};
    let typeByServiceId = null;
    let appData = null;

    const finallyHandler = () => {
      setAppLoading(false);
    };

    const errorHandler = (error) => {
      if (error instanceof Response && error?.status === 302) {
        throw error;
      }
      logError(error);
      setAppLoading(false);
      return error;
    };

    const appDataHandler = async (data) => {
      if (data) {
        setAppLoading(false);
        const redirectTo = onSettingsLoaded();
        if (redirectTo !== null) {
          setAppLoading(true);
          return redirectTo;
        }

        try {
          let allTemplates = [];
          let companyPharmacies = [];
          if (data?.companyID) {
            companyPharmacies = getPharmaciesByCompanyId(data?.companyID);
            allTemplates = getAllTemplates(data?.companyID);
          }
          typeByServiceId = await getServiceTypeById(serviceId);
          return { typeByServiceId, allTemplates, companyPharmacies };
        } catch (error) {
          logError(error);
          setAppLoading(false);
          return error;
        }
      }

      return data;
    };
    appData = getApplicationData()
      .then(appDataHandler)
      .catch(errorHandler)
      .finally(finallyHandler);

    return defer({ appData });
  };

  const locationLoader = async () => {
    // setLocationLoading(true);
    let appData = null;

    const finallyHandler = () => {
      // setLocationLoading(false);
      setAppLoading(false);
    };

    const errorHandler = (error) => {
      console.log(error);
      if (error instanceof Response && error?.status === 302) {
        throw error;
      }
      logError(error);
      // setLocationLoading(false);
      setAppLoading(false);
      return error;
    };

    const appDataHandler = async (data) => {
      if (data?.companyID) {
        setAppLoading(false);
        const redirectTo = onSettingsLoaded();
        if (redirectTo !== null) {
          setAppLoading(true);
          return redirectTo;
        }

        try {
          const companyPharmacies = getPharmaciesByCompanyId(data?.companyID);
          return { data, companyPharmacies };
        } catch (error) {
          logError(error);
          // setLocationLoading(false);
          setAppLoading(false);
          return error;
        }
      }

      return data;
    };
    appData = getApplicationData()
      .then(appDataHandler)
      .catch(errorHandler)
      .finally(finallyHandler);

    return defer({ appData });
  };

  const locationAddNewLoader = async ({ params }) => {
    const { pharmacyId } = params || {
      pharmacyId: "00000000-0000-0000-0000-000000000000",
    };
    // setLocationLoading(true);
    let appData = null;

    const finallyHandler = () => {
      // setLocationLoading(false);
      setAppLoading(false);
    };

    const errorHandler = (error) => {
      console.log(error);
      if (error instanceof Response && error?.status === 302) {
        throw error;
      }
      logError(error);
      // setLocationLoading(false);
      setAppLoading(false);
      return error;
    };

    const appDataHandler = async (data) => {
      if (data?.companyID) {
        setAppLoading(false);
        const redirectTo = onSettingsLoaded();
        if (redirectTo !== null) {
          setAppLoading(true);
          return redirectTo;
        }

        try {
          const servicesByPharmacyId = await getServicesByPharmacyId(
            pharmacyId
          );
          const teamPermissions = await getPermissions();
          return { data, teamPermissions, servicesByPharmacyId };
        } catch (error) {
          logError(error);
          // setLocationLoading(false);
          setAppLoading(false);
          return error;
        }
      }

      return data;
    };
    appData = getApplicationData()
      .then(appDataHandler)
      .catch(errorHandler)
      .finally(finallyHandler);

    return defer({ appData });
  };

  const OrbitSettingsLoader = async () => {
    setCurrentOrbitLoading(true);
    let appData = null;

    const finallyHandler = () => {
      setCurrentOrbitLoading(false);
      setAppLoading(false);
    };

    const errorHandler = (error) => {
      console.log(error);
      if (error instanceof Response && error?.status === 302) {
        throw error;
      }
      logError(error);
      setCurrentOrbitLoading(false);
      setAppLoading(false);
      return error;
    };
    const appDataHandler = (data) => {
      try {
        return getOrbitProfile();
      } catch (error) {
        logError(error);
        setCurrentOrbitLoading(false);
        setAppLoading(false);
        return error;
      }
    };

    appData = getApplicationData()
      .then(appDataHandler)
      .catch(errorHandler)
      .finally(finallyHandler);

    return defer({ appData });
  };
  const orbitDashboardLoader = async () => {
    setCurrentOrbitLoading(true);
    setIsMonthlyReportLoading(true);
    setIsOrbitEarningLoading(true);
    setIsUserSessionLoading(true);
    setIsPotentialEarningLoading(true);
    setIsFollowupLoading(true);
    setIsOtherDetailsLoading(true);
    setIsActiveServicesLoading(true);
    let appData = null;

    const finallyHandler = () => {
      setCurrentOrbitLoading(false);
      setAppLoading(false);
    };

    const errorHandler = (error) => {
      console.log(error);
      if (error instanceof Response && error?.status === 302) {
        throw error;
      }
      logError(error);
      setCurrentOrbitLoading(false);
      setIsMonthlyReportLoading(false);
      setIsOrbitEarningLoading(false);
      setIsUserSessionLoading(false);
      setIsPotentialEarningLoading(false);
      setIsFollowupLoading(false);
      setIsOtherDetailsLoading(false);
      setIsActiveServicesLoading(false);
      setAppLoading(false);
      return error;
    };
    const authTokenHandler = (authToken) => {
      if (authToken) {
        return getApplicationData();
      } else {
        setCurrentOrbitLoading(false);
        setIsMonthlyReportLoading(false);
        setIsOrbitEarningLoading(false);
        setIsUserSessionLoading(false);
        setIsPotentialEarningLoading(false);
        setIsFollowupLoading(false);
        setIsOtherDetailsLoading(false);
        setIsActiveServicesLoading(false);
        setAppLoading(false);
      }
      return null;
    };
    const appDataHandler = (data) => {
      try {
        const { user } = data;
        const { companyID } = user;
        const storedOption = localStorage.getItem("selectedOption");
        const currentAppState = store.getState().app;
        const { companyId } = currentAppState || {};

        if (user?.accountType === 2 || storedOption === "orbit") {
          const currentDate = moment();
          const currentOrbitState = store.getState().orbit;
          const { isTestMode } = currentOrbitState || false;
          const firstDayOfMonth = moment(currentDate).startOf("month");
          const lastDayOfMonth = moment(currentDate).endOf("month");
          const startDate = firstDayOfMonth.format("YYYY-MM-DDTHH:mm:ss");
          const endDate = lastDayOfMonth.format("YYYY-MM-DDTHH:mm:ss");
          const currentSessionId = localStorage.getItem("currentSessionId");
          getUser("");
          if (checkUserPermissions(accessOwner)) {
            const strDate =
              startDate instanceof Date ? startDate : new Date(startDate);
            const enDate =
              endDate instanceof Date ? endDate : new Date(endDate);

            // Format dates to ISO string with UTC midnight
            const formattedStartDate = new Date(
              Date.UTC(
                strDate.getFullYear(),
                strDate.getMonth(),
                strDate.getDate(),
                0,
                0,
                0,
                0
              )
            ).toISOString();

            const formattedEndDate = new Date(
              Date.UTC(
                enDate.getFullYear(),
                enDate.getMonth(),
                enDate.getDate(),
                0,
                0,
                0,
                0
              )
            ).toISOString();
            getOrbitAgencyMonthlyReport({
              formattedStartDate,
              formattedEndDate,
              companyID: user?.companyID,
            });
            getOrbitAgencyUsers(user?.companyID);
            getOrbitAgencyDetails(user?.companyID);
            getOrbitServicesWorkPlanner(user?.companyID);
            getOrbitPharmanautsWorkPlanner(user?.companyID);
            getOrbitWorkPlanner(user?.companyID);
            getOrbitAgencyOtherDetails(user?.companyID);
            getOrbitAgencyPharmacies({
              companyId: user?.companyID,
              workLoadType: "1",
            });
            getOthersAvailable();
            getOrbitAgencyOtherPharmacies({
              companyId: companyID,
              workLoadType: "2",
            });
          } else {
            getOrbitMonthlyReport({ startDate, endDate });
            getOrbitEarnings({ startDate, endDate });
            getOrbitFollowUpServices();
            getOrbitPotentialEarnings();
            getOrbitParkedServices(isTestMode);
            getOrbitActiveServices({ currentSessionId, isTestMode });
            getOrbitOtherDetails();
            getOrbitUserSessions();
            getPaymentDetails();
            // getOrbitAgencyUsers(user?.companyID);
            // getOrbitAgencyDetails(user?.companyID);
            // getOrbitAgencyOtherDetails(user?.companyID);
            // getOrbitAgencyPharmacies({
            //   companyId: user?.companyID,
            //   workLoadType: "1",
            // });
            // getOrbitAgencyOtherPharmacies({
            //   companyId: companyID,
            //   workLoadType: "2",
            // });
          }

          if (user.companyID !== null) {
            getOrbitTeamByCompanyId(user?.companyID);
          }

          return data;
        } else {
          const redirectTo = onOrbitLoaded();
          return redirectTo;
        }
      } catch (error) {
        logError(error);
        setCurrentOrbitLoading(false);
        setIsMonthlyReportLoading(false);
        setIsOrbitEarningLoading(false);
        setIsUserSessionLoading(false);
        setIsPotentialEarningLoading(false);
        setIsFollowupLoading(false);
        setIsOtherDetailsLoading(false);
        setIsActiveServicesLoading(false);
        setAppLoading(false);
        return error;
      }
    };

    appData = getAuthToken()
      .then(authTokenHandler)
      .then(appDataHandler)
      .catch(errorHandler)
      .finally(finallyHandler);

    return defer({ appData });
  };

  const consentFormLoader = async ({ params }) => {
    const { serviceId } = params || {};
    setConsentLoading(true);
    let appData = null;

    const finallyHandler = () => {
      setConsentLoading(false);
      setAppLoading(false);
    };

    const errorHandler = (error) => {
      console.log(error);
      if (error instanceof Response && error?.status === 302) {
        throw error;
      }
      logError(error);
      setConsentLoading(false);
      setAppLoading(false);
      return error;
    };

    const appDataHandler = (data) => {
      try {
        const consentData = getAllConsentData(serviceId);
        return { data, consentData };
      } catch (error) {
        logError(error);
        setConsentLoading(false);
        setAppLoading(false);
        return error;
      }
    };

    appData = getApplicationData()
      .then(appDataHandler)
      .catch(errorHandler)
      .finally(finallyHandler);

    return defer({ appData });
  };
  const differentOrganizationLoader = async () => {
    setAppLoading(true);
    let appData = null;

    const errorHandler = (error) => {
      console.log(error);
      if (error instanceof Response && error?.status === 302) {
        throw error;
      }
      logError(error);
      setAppLoading(false);
      return error;
    };

    const appDataHandler = (data) => {
      try {
        const currentOnBoardingState = store.getState().onboardingVerification;
        const { userData } = currentOnBoardingState || {};
        const { companyID, newCompanyID } = userData || {};
        const orgData = getCompanyNames({
          oldCompanyId: companyID,
          newCompanyId: newCompanyID,
        });
        return { data, orgData };
      } catch (error) {
        logError(error);
        setConsentLoading(false);
        setAppLoading(false);
        return error;
      }
    };

    appData = Promise.resolve(null).then(appDataHandler).catch(errorHandler);
    return defer({ appData });
  };

  const emailsAndSmsTemplatesLoader = async ({ params }) => {
    let allTemplates = null;
    let appData = null;

    const finallyHandler = () => {
      setAppLoading(false);
    };

    const errorHandler = (error) => {
      if (error instanceof Response && error?.status === 302) {
        throw error;
      }
      logError(error);
      setAppLoading(false);
      return error;
    };

    const appDataHandler = async (data) => {
      if (data?.companyID) {
        const { companyID } = data || {
          companyID: "00000000-0000-0000-0000-000000000000",
        };

        setAppLoading(false);
        const redirectTo = onSettingsLoaded();
        if (redirectTo !== null) {
          setAppLoading(true);
          return redirectTo;
        }

        try {
          allTemplates = await getEmailsAndSmsTemplates(companyID);
          return { allTemplates };
        } catch (error) {
          logError(error);
          setAppLoading(false);
          return error;
        }
      }

      return data;
    };
    appData = getApplicationData()
      .then(appDataHandler)
      .catch(errorHandler)
      .finally(finallyHandler);

    return defer({ appData });
  };

  // #endregion

  // #region Routers and config related function
  const signout = async () => {
    localStorage.setItem("loginRequired", true);
    showInfo("Logging out please wait...", false);
    localStorage.setItem("selectedOption", null);
    setAppLoading(true);
    updateUserCurrentStatus(1).then((result) => {
      removeAuth();
    });
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  };

  const loadRoutes = (root) => {
    let customRoutes = [
      {
        path: "/",
        element: <Dashboard />,
        loader: dashboardLoader,
        shouldRevalidate: () => false,
        errorElement: () => {
          return <NotFound />;
        },
      },
      {
        path: "/clinicalchecks/:pharmacyId",
        element: <ClinicalChecks />,
        shouldRevalidate: () => false,
        loader: getClinicalChecksLoader,
        errorElement: () => {
          return <NotFound />;
        },
      },
      {
        path: "/clinicalservices/:clinicalServiceId",
        element: <ClinicalServices />,
        loader: NmsLoader,
        shouldRevalidate: () => false,
        errorElement: () => {
          return <NotFound />;
        },
      },
      {
        path: "/nmsintervention/:serviceId/",
        element: <NmsIntervention />,
        loader: NmsLoader,
        shouldRevalidate: () => false,
        errorElement: () => {
          return <NotFound />;
        },
      },
      {
        path: "/privateservice/:serviceId/",
        element: <PrivateService />,
        loader: privateServiceLoader,
        shouldRevalidate: () => false,
        errorElement: () => {
          return <NotFound />;
        },
      },
      {
        path: "/patientdashboard/:pharmacyId",
        element: <PatientDashboard />,
        // loader: patientTabLoader,
        shouldRevalidate: () => false,
        errorElement: () => {
          return <NotFound />;
        },
      },
      {
        path: "/patientform",
        element: <PatientForm />,
        shouldRevalidate: () => false,
        errorElement: () => {
          return <NotFound />;
        },
      },
      {
        path: "/pharmacyoverview/:pharmacyId",
        element: <PharmacyOverview />,
        loader: pharmacyOverviewTabLoader,
        shouldRevalidate: () => true,
        errorElement: () => {
          return <NotFound />;
        },
      },
      {
        path: "/pharmacypatient/:pharmacyId",
        element: <PharmacyPatient />,
        loader: pharmacyPatientTabLoader,
        shouldRevalidate: () => true,
        errorElement: () => {
          return <NotFound />;
        },
      },
      {
        path: "/pharmacyservices/:pharmacyId",
        element: <PharmacyServices />,
        loader: pharmacyServicesTabLoader,
        shouldRevalidate: () => true,
        errorElement: () => {
          return <NotFound />;
        },
      },
      {
        path: "/settings/*",
        element: <General />,
        loader: generalDetailsLoader,
        shouldRevalidate: () => false,
        errorElement: () => {
          return <NotFound />;
        },
      },
      {
        path: "/settings/general",
        element: <General />,
        loader: generalDetailsLoader,
        shouldRevalidate: () => false,
        errorElement: () => {
          return <NotFound />;
        },
      },
      {
        path: "/settings/locations",
        element: <Locations />,
        loader: locationLoader,
        shouldRevalidate: () => false,
        errorElement: () => {
          return <NotFound />;
        },
      },
      {
        path: "/settings/billing",
        element: <Billing />,
        loader: billingDetailsLoader,
        shouldRevalidate: () => false,
        errorElement: () => {
          return <NotFound />;
        },
      },
      {
        path: "/settings/locations/new/:pharmacyId",
        element: <LocationsAddNew />,
        loader: locationAddNewLoader,
        shouldRevalidate: () => false,
        errorElement: () => {
          return <NotFound />;
        },
      },
      {
        path: "/settings/teams/*",
        element: <Teams />,
        shouldRevalidate: () => false,
        errorElement: () => {
          return <NotFound />;
        },
      },
      {
        path: "/settings/teams/members",
        element: <Members />,
        loader: membersLoader,
        shouldRevalidate: () => false,
        errorElement: () => {
          return <NotFound />;
        },
      },
      {
        path: "/settings/teams/members/new",
        element: <MembersAddNew />,
        loader: memberAddNewLoader,
        shouldRevalidate: () => false,
        errorElement: () => {
          return <NotFound />;
        },
      },
      {
        path: "/settings/teams/members/:memberId",
        element: <MembersUpdate />,
        loader: memberByIdLoader,
        shouldRevalidate: () => false,
        errorElement: () => {
          return <NotFound />;
        },
      },
      {
        path: "/settings/teams/roles",
        element: <Roles />,
        loader: rolesAndPermissionsLoader,
        shouldRevalidate: () => false,
        errorElement: () => {
          return <NotFound />;
        },
      },
      {
        path: "/settings/teams/roles/:roleId",
        element: <UpdateRole />,
        loader: rolesByIdLoader,
        shouldRevalidate: () => false,
        errorElement: () => {
          return <NotFound />;
        },
      },
      {
        path: "/settings/teams/roles/new",
        element: <RolesAddNew />,
        loader: rolesAddNewLoader,
        shouldRevalidate: () => false,
        errorElement: () => {
          return <NotFound />;
        },
      },
      {
        path: "/settings/services",
        element: <Services />,
      },
      {
        path: "/settings/services/types",
        element: <ServiceTypes />,
        loader: typeLoader,
        shouldRevalidate: () => false,
        errorElement: () => {
          return <NotFound />;
        },
      },
      {
        path: "/settings/services/types/new",
        element: <TypesAddNew />,
        loader: typeAddNewLoader,
        shouldRevalidate: () => false,
        errorElement: () => {
          return <NotFound />;
        },
      },
      {
        path: "/settings/services/types/:serviceId",
        element: <TypesUpdate />,
        loader: typeByServiceIdLoader,
        shouldRevalidate: () => false,
        errorElement: () => {
          return <NotFound />;
        },
      },
      {
        path: "/settings/teams/templates",
        element: <Templates />,
        shouldRevalidate: () => false,
        errorElement: () => {
          return <NotFound />;
        },
      },
      {
        path: "/settings/templates/consultation",
        element: <CustomTemplate />,
        loader: consultationLoader,
        shouldRevalidate: () => false,
        errorElement: () => {
          return <NotFound />;
        },
      },
      {
        path: "/settings/templates/consultation/new",
        element: <AddNewCustomTemplate />,
        loader: addConsultationLoader,
        shouldRevalidate: () => false,
        errorElement: () => {
          return <NotFound />;
        },
      },
      {
        path: "/settings/communication/messagetemplate",
        element: <EmailAndSms />,
        loader: emailsAndSmsTemplatesLoader,
        shouldRevalidate: () => false,
        errorElement: () => {
          return <NotFound />;
        },
      },
      {
        path: "/settings/communication/messagetemplate/new",
        element: <AddTemplate />,
        // loader: rolesAndPermissionsLoader,
        shouldRevalidate: () => false,
        errorElement: () => {
          return <NotFound />;
        },
      },
      {
        path: "/settings/communication/messagetemplate/update",
        element: <UpdateEmailTemplate />,
        // loader: rolesAndPermissionsLoader,
        shouldRevalidate: () => false,
        errorElement: () => {
          return <NotFound />;
        },
      },
      {
        path: "/orbit",
        element: <Orbit root={root} />,
        loader: orbitDashboardLoader,
        shouldRevalidate: () => false,
        errorElement: () => {
          return <NotFound />;
        },
      },
      {
        path: "/nmsconsent/:serviceId",
        element: <NmsConsent />,
        loader: consentFormLoader,
        shouldRevalidate: () => false,
        errorElement: () => {
          return <NotFound />;
        },
      },
      {
        path: "/NewMedicineService",
        element: <NewMedicineService />,
        shouldRevalidate: () => false,
        errorElement: () => {
          return <NotFound />;
        },
      },
      {
        path: "/Abort",
        element: <Abort />,
        shouldRevalidate: () => false,
        errorElement: () => {
          return <NotFound />;
        },
      },
      {
        path: "/moreInfo",
        element: <MoreInfo />,
        shouldRevalidate: () => false,
        errorElement: () => {
          return <NotFound />;
        },
      },
      {
        path: "/personalInfo",
        element: <PersonalInfo />,
        shouldRevalidate: () => false,
        errorElement: () => {
          return <NotFound />;
        },
      },
      {
        path: "/makeBooking",
        element: <MakeBooking />,
        shouldRevalidate: () => false,
        errorElement: () => {
          return <NotFound />;
        },
      },
      {
        path: "/successPage",
        element: <SuccessPage />,
        shouldRevalidate: () => false,
        errorElement: () => {
          return <NotFound />;
        },
      },
      {
        path: "*",
        element: <NotFound />,
        shouldRevalidate: () => false,
        errorElement: () => {
          return <NotFound />;
        },
      },
      {
        path: "/orbitsettings/professionaldetails",
        element: <ProfessionalDetails />,
        loader: OrbitSettingsLoader,
        shouldRevalidate: () => false,
        errorElement: () => {
          return <NotFound />;
        },
      },
      {
        path: "/orbitsettings/compliancedocuments",
        element: <ComplianceDocuments />,
        loader: OrbitSettingsLoader,
        shouldRevalidate: () => false,
        errorElement: () => {
          return <NotFound />;
        },
      },
      {
        path: "/orbitsettings/accreditationsskills",
        element: <AccreditationsSkills />,
        loader: OrbitSettingsLoader,
        shouldRevalidate: () => false,
        errorElement: () => {
          return <NotFound />;
        },
      },
      {
        path: "/orbitsettings/availability",
        element: <Availability />,
        loader: OrbitSettingsLoader,
        shouldRevalidate: () => false,
        errorElement: () => {
          return <NotFound />;
        },
      },
      {
        path: "/orbitsettings/changepassword",
        element: <ChangePassword />,
        shouldRevalidate: () => false,
        errorElement: () => {
          return <NotFound />;
        },
      },
      {
        path: "/orbitsettings/paymentdetails",
        element: <PaymentDetails />,
        loader: OrbitSettingsLoader,
        shouldRevalidate: () => false,
        errorElement: () => {
          return <NotFound />;
        },
      },
      {
        path: "/useronboarding/success",
        element: <Success />,
        shouldRevalidate: () => false,
        errorElement: () => {
          return <NotFound />;
        },
      },
      {
        path: "/useronboarding/success",
        element: <Success />,
        shouldRevalidate: () => false,
        errorElement: () => {
          return <NotFound />;
        },
      },
      {
        path: "/onboardingverification",
        element: <OnboardingVerification root={root} />,
        shouldRevalidate: () => false,
        errorElement: () => {
          return <NotFound />;
        },
      },
      {
        path: "/invitemembers",
        element: <InviteMembers root={root} />,
        loader: dashboardLoader,
        shouldRevalidate: () => false,
        errorElement: () => {
          return <NotFound />;
        },
      },
      {
        path: "/usersignup/:code",
        element: <SignUp root={root} />,
        loader: signupLoader,
        shouldRevalidate: () => false,
        errorElement: () => {
          return <NotFound />;
        },
      },
      {
        path: "/useronboarding/verificationemail",
        element: <VerificationEmail />,
        shouldRevalidate: () => false,
        errorElement: () => {
          return <NotFound />;
        },
      },
      {
        path: "/onboardingResetPassword/onboardingResetPassword",
        element: <OnboardingResetPassword />,
        shouldRevalidate: () => false,
        errorElement: () => {
          return <NotFound />;
        },
      },
      {
        path: "/neworganization",
        element: <DifferentOrganization />,
        loader: differentOrganizationLoader,
        shouldRevalidate: () => false,
        errorElement: () => {
          return <NotFound />;
        },
      },
      {
        path: "/selectionScreen",
        element: <SelectionScreen />,
        shouldRevalidate: () => false,
        errorElement: () => {
          return <NotFound />;
        },
      },
      {
        path: "/consultationForm",
        element: <ConsultationForm />,
        shouldRevalidate: () => false,
        errorElement: () => {
          return <NotFound />;
        },
      },
    ];

    return customRoutes;
  };
  // #endregion

  // #region Return Object

  return {
    auth,
    appState,
    pharmacyServiceState,
    nmsServiceState,
    documentState,
    clincicalChecksState,
    orbitState,
    generalsState,
    memberState,
    billingState,
    locationState,
    consentState,
    onboardingVerificationState,
    dashboardLoader,
    pharmacyServicesTabLoader,
    rolesAndPermissionsLoader,
    getClinicalChecksLoader,
    orbitDashboardLoader,
    signout,
    loadRoutes,
    getAuthToken,
    generalDetailsLoader,
    NmsLoader,
    getTimelineDataForClinicalServiceId,
  };
  // #endregion
};
export { store };
export default useTitanApp;
